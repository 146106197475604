import { basePath } from './config';

export async function setNewMonthInvoice(data, token) {

  const url = `${basePath}/api/config/set-month-invoice`;
  const params = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
	  "Content-Type": "application/json",
	  "Authorization": token
    }
  };

  return await fetch(url, params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      if (result.newMonth) {
        return { ok: true, message: "Mes guardado correctamente" };
      }
      return { ok: false, message: result.msg };
    })
    .catch(err => {
      return { ok: false, message: err.msg };
    });
};

export async function getMonthInvoice(token) {
	const url = `${basePath}/api/config/get-month-invoice`;
	const params = {
	  method: "GET",
	  body: JSON.stringify(),
	  headers: {
		"Content-Type": "application/json",
		"Authorization": token
	  }
	};
  
	return await fetch(url, params)
		.then(response => {
			return response.json();
		})
		.then(result => {
			return result;
		})
		.catch(err => {
			return err.message;
		})
  };

  export async function setNewMonthInvoiceOut(data, token) {

	const url = `${basePath}/api/config/set-month-invoice-out`;
	const params = {
	  method: "POST",
	  body: JSON.stringify(data),
	  headers: {
		"Content-Type": "application/json",
		"Authorization": token
	  }
	};
  
	return await fetch(url, params)
	  .then(response => {
		return response.json();
	  })
	  .then(result => {
		if (result.newMonth) {
		  return { ok: true, message: "Mes guardado correctamente" };
		}
		return { ok: false, message: result.msg };
	  })
	  .catch(err => {
		return { ok: false, message: err.msg };
	  });
  };
  
  export async function getMonthInvoiceOut(token) {
	  const url = `${basePath}/api/config/get-month-invoice-out`;
	  const params = {
		method: "GET",
		body: JSON.stringify(),
		headers: {
		  "Content-Type": "application/json",
		  "Authorization": token
		}
	  };
	
	  return await fetch(url, params)
		  .then(response => {
			  return response.json();
		  })
		  .then(result => {
			  return result;
		  })
		  .catch(err => {
			  return err.message;
		  })
	};