import { basePath } from './config';

export async function getLastNumberReport(token) {
	const url = `${basePath}/api/payment-report/last-number`;
	const params = {
	  method: "GET",
	  body: JSON.stringify(),
	  headers: {
		"Content-Type": "application/json",
		"Authorization": token
	  }
	};
  
	return await fetch(url, params)
		.then(response => {
			return response.json();
		})
		.then(result => {
			return result;
		})
		.catch(err => {
			return err.message;
		})
  };

  export async function downloadPaymentReport(data, token) {
	const url = `${basePath}/api/payment-report/`;
	const params = {
	  method: "POST",
	  body: JSON.stringify(data),
	  headers: {
		"Content-Type": "application/json",
		"Authorization": token
	  }
	};
  
	return await fetch(url, params)
	  .then(response => {
		return response.blob();
	  })
	  .then(blob => {
		const link = document.createElement("a");
    	link.href = URL.createObjectURL(blob);
    	link.setAttribute("download", "report.csv");
    	document.body.appendChild(link);
    	link.click();
	  })
	  .catch(err => {
		return { ok: false, message: err.msg };
	  });
  };

  export async function updatePaymentReport(data, token) {
	const url = `${basePath}/api/payment-report/update`;
	const params = {
	  method: "POST",
	  body: JSON.stringify(data),
	  headers: {
		"Content-Type": "application/json",
		"Authorization": token
	  }
	};
  
	return await fetch(url, params)
		.then(response => {
			return response.json();
		})
		.then(result => {
			return result;
		})
		.catch(err => {
			return err.message;
		})
  };


  export async function downloadExtendedReport(data, token) {
	const url = `${basePath}/api/payment-report/extended/`;
	const params = {
	  method: "POST",
	  body: JSON.stringify(data),
	  headers: {
		"Content-Type": "application/json",
		"Authorization": token
	  }
	};
  
	return await fetch(url, params)
	  .then(response => {
		return response.blob();
	  })
	  .then(blob => {
		const link = document.createElement("a");
    	link.href = URL.createObjectURL(blob);
    	link.setAttribute("download", "report.csv");
    	document.body.appendChild(link);
    	link.click();
	  })
	  .catch(err => {
		return { ok: false, message: err.msg };
	  });
  };

  export async function downloadExtendedReportInvoicesOut(data, token) {
	const url = `${basePath}/api/payment-report/extended-out/`;
	const params = {
	  method: "POST",
	  body: JSON.stringify(data),
	  headers: {
		"Content-Type": "application/json",
		"Authorization": token
	  }
	};
  
	return await fetch(url, params)
	  .then(response => {
		return response.blob();
	  })
	  .then(blob => {
		const link = document.createElement("a");
    	link.href = URL.createObjectURL(blob);
    	link.setAttribute("download", "report-out.csv");
    	document.body.appendChild(link);
    	link.click();
	  })
	  .catch(err => {
		return { ok: false, message: err.msg };
	  });
  };
