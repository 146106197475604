import React, { useState } from 'react';
import LogoForm from '../assets/img/logo-form.png';
import '../css/login.css';
import ButtonSubmit from '../components/ButtonSubmit';
import { Link, Navigate } from 'react-router-dom';
import { signUpApi } from '../api/user';
import { getAccessToken } from '../api/auth';
import { useTranslation } from 'react-i18next';

const RegisterPage = () => {

	
	const {t} = useTranslation();

	const [ user, setUser ] = useState({
		email: '',
		password: '',
		repeatPassword: ''
	});

	const [ error, setError ] = useState({
		msg: '',
		status: false
	});

	const { email, password, repeatPassword } = user;

	const [ exito, setExito ] = useState(false);

	const handleChange = e => {
		setUser({
			...user,
			[e.target.name] : e.target.value
		})
	};

	const handleSubmit = async e => {
		e.preventDefault();

		if(email.trim() === '' || password.trim() === '' || repeatPassword.trim() === '') {
			setError({
				msg: t('Todos los campos son obligatorios'),
				status: true
			});
			return;
		}

		if(password.length < 6 || repeatPassword.length < 6) {
			setError({
				msg: t('La contraseña debe tener mínimo 6 caracteres'),
				status: true
			});
			return;
		}
		if (password !== repeatPassword) {
			setError({
				msg: t('Las contraseñas no coinciden'),
				status: true
			});
			return;
		}
		
		const result = await signUpApi(user);

		if(!result.ok) {
			setError({
				msg: result.message,
				status: true
			});
			
		} else {
			setError({
				msg: "",
				status: false
			});
			setUser({
				email: '',
				password: '',
				repeatPassword: ''
			});
			setExito(true);
		}

	}

	if (getAccessToken()) {
		return <Navigate to="/" />
	}

	return ( 
		<div className="d-flex justify-content-center align-items-center fullpage">
			<div className="d-flex flex-column align-items-center">
				<img src={LogoForm} alt="Logo" className="logo-login mb-4 px-3" />
				<div className="card w-100">
					<div className="card-body">
						{ error.status ? <div className="alert alert-danger text-center" role="alert">{error.msg}</div> : null }
						{ exito ? <div className="alert alert-success text-center registro-exitoso" role="alert">
							{t('Usuario creado correctamente')}.<p className="mb-0 pb-0 link"><Link to="/login">{t('Iniciar sesión')}</Link></p></div> : null 
						}
						<form onSubmit={handleSubmit}>
							<p className="text-muted"><span>{t('Crear una nueva cuenta de proveedores')}</span></p>
							<div className="mb-3">
								<input 
									type="email" 
									className="form-control" 
									name="email" 
									onChange={handleChange}
									value={email}
									placeholder="Email" 
								/>
							</div>
							<div className="mb-3">
								<input 
									type="password" 
									className="form-control" 
									name="password"
									onChange={handleChange}
									value={password} 
									placeholder={t('Contraseña')} 
								/>
							</div>
							<div className="mb-3">
								<input 
									type="password" 
									className="form-control" 
									name="repeatPassword" 
									onChange={handleChange}
									value={repeatPassword} 
									placeholder={t('Repetir contraseña')} 
								/>
							</div>
							<ButtonSubmit text={t('Registrar')} />
						</form>
					</div>
				</div>
				<div className="mt-3">
					<Link className="col-7" to="/login">
						<span>{t('¿Ya estás registrado?')}</span>
					</Link>
				</div>
			</div>
		</div>
	 );
}
 
export default RegisterPage;