import { useState, useEffect } from "react";
import { getUserEnterpriseById } from "../../api/enterprise";
import Paypal from '../../assets/img/paypal-icon.svg';
import Bank from '../../assets/img/bank.svg';
import Amex from '../../assets/img/amex.png';
import Mastercard from '../../assets/img/mastercard.png';
import { Button, Modal } from 'react-bootstrap';
import { EyeFill, PencilFill } from 'react-bootstrap-icons';
import {  updateInvoiceOut } from "../../api/invoice";
import ButtonSubmit from "../ButtonSubmit";
import moment from 'moment';

const paymentModeFn = (payment) => {

	if(payment === 'banco') {
		return (
			<td className="text-nowrap">
				<img src={Bank} className="logo-money" alt="Transferencia" />
				<span>Transferencia</span>
			</td>)
	} else if (payment === 'paypal') {
		return (
			<td className="text-nowrap"> 
				<img src={Paypal} className="logo-money" alt="Paypal" />
				<span>Paypal</span>
			</td>)
	} else if (payment === 'amex') {
		return ( 
			<td className="text-nowrap">
				<img src={Amex} className="logo-money" alt="AMEX" />
				<span>Tarjeta AMEX</span>
			</td>)
	} else if (payment === 'mastercard') {
		return ( 
			<td className="text-nowrap">
				<img src={Mastercard} className="logo-money" alt="Mastercard" />
				<span>Tarjeta Mastercard</span>
			</td>)
	} else {
		return (
			<td className="text-nowrap">
				<img src={Bank} className="logo-money" alt="Giro" />
				<span>Giro Bancario</span>
			</td>
		)
	};
	
}

const RowInvoicesOut = ({invoice, token}) => {

	const [ supplier, setSupplier ] = useState([]);

	const { date, expirationDate, accountName, iban, paypalEmail, refSupplier, description, price, iva, irpf, total, payment, ref, paymentMode } = invoice;
	const [ file, setFile ] = useState('');

	const [ estadoInvoice, setEstadoInvoice ] = useState({
		_id: invoice._id || '',
		date: invoice.date || '',
		expirationDate: invoice.expirationDate || '',
		description: invoice.description || '',
		price: invoice.price || 0,
		iva: invoice.iva || 0,
		irpf: invoice.irpf || 0,
		payment: invoice.payment || '',
		ref: invoice.ref || '',
		refSupplier: invoice.refSupplier || '',
		file: ''
	})

	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const [showEdit, setShowEdit] = useState(false);

	const handleCloseEdit = () => setShowEdit(false);

	const [ exito, setExito ] = useState(false);
	const [ error, setError ] = useState({
		msg: '',
		status: false
	});

	const handleEdit = () => {
		setShowEdit(true);
		setShow(false)
	}

	useEffect(() => {

		async function fetchDatos() {
			await getUserEnterpriseById(invoice.supplier,token).then(response => {
				setSupplier(response.enterprise);
			});
		}

		fetchDatos();

	}, [token, invoice.supplier]);

	const handleChange = e => {
		setEstadoInvoice({
			...estadoInvoice,
			[e.target.name] : e.target.value
		})
	}

	const handleFile = e => {
		setFile(...file, e.target.files[0]);
	}


	const handleSubmit = async e => {
		
		e.preventDefault();

		if(estadoInvoice.date.trim() === '' || estadoInvoice.expirationDate.trim() === '' || estadoInvoice.price === ''  || estadoInvoice.iva === '' || estadoInvoice.irpf === '' || estadoInvoice.description.trim() === '' || estadoInvoice.payment === '') {
			setError({
				msg: 'Alguno de los campos obligatorios no están rellenados.',
				status: true
			});
			setExito(false);
			return;
		}

		if(estadoInvoice.date > estadoInvoice.expirationDate) {
			setError({
				msg: 'La fecha de vencimiento no puede ser previa a la fecha de emisión.',
				status: true
			});
			setExito(false);
			return;
		}

		if(file) {
			estadoInvoice.file = file;
		}

		const result = await updateInvoiceOut(estadoInvoice, token);

		if (result.ok) {
			setExito(true);
			setError({
				msg: '',
				status: false
			});
			setTimeout(function() { 
				window.location.reload();
			}, 400);
		} else {
			setError({		
				msg: result.message,
				status: true
			});
			setExito(false);
			setEstadoInvoice({
				_id: invoice._id || '',
				date: invoice.date || '',
				expirationDate: invoice.expirationDate || '',
				description: invoice.description || '',
				price: invoice.price || 0,
				iva: invoice.iva || 0,
				irpf: invoice.irpf || 0,
				payment: invoice.payment || '',
				ref: invoice.ref || '',
				refSupplier: invoice.refSupplier || '',
				file: ''
			});
		}
	}


	return ( 
		<tr className="bg-white my-2">
			<td className="bold text-nowrap">{ref}</td>
			<td className="text-truncate">
				{ supplier.companyName ? supplier.companyName : supplier.name + ' ' + supplier.surname}
			</td>
			<td className="text-nowrap">{moment(date).format("DD/MM/YYYY")}</td>
			<td className="text-nowrap">
				{payment ==='pendiente' ? <span className="badge text-bg-danger">Pendiente</span> : <span className="badge text-bg-success">Pagada</span> }
			</td>
			{paymentModeFn(paymentMode)}
			<td className="text-nowrap">{total} €</td>
			<td className="text-right text-nowrap">
				<div>
					<Button variant="warning" className="px-2 py-0 mx-1" onClick={handleShow}>
						<PencilFill />
					</Button>
					<a className="btn btn-primary px-2 py-0" target="_blank" rel="noreferrer" href={`https://api-proveedores.prensalink.com/invoices-out/${ref}.pdf`}>
						<EyeFill />
					</a>
				</div>
				
				<Modal show={show} onHide={handleClose}>
					<Modal.Header closeButton>
						<Modal.Title>{ref}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<ul>
							<li><span className="bold">Referencia:</span> {ref}</li>
							<li><span className="bold">Proveedor</span>: {supplier.companyName ? supplier.companyName : supplier.name + ' ' + supplier.surname}</li>
							<li><span className="bold">Fecha:</span> {moment(date).format("DD/MM/YYYY")}</li>
							<li><span className="bold">Vencimiento:</span> {moment(expirationDate).format("DD/MM/YYYY")}</li>
							<li><span className="bold">Número de factura del proveedor:</span> {refSupplier}</li>
							<li><span className="bold">Estado del pago:</span> {payment}</li>
							<li><span className="bold">Método de pago:</span> {paymentMode}</li>
							{paymentMode === 'banco' ? (
								<>
									<li><span className="bold">Beneficiario:</span> {accountName}</li>
									<li><span className="bold">IBAN:</span> {iban}</li>
								</>
							) : null}
							{paymentMode === 'paypal' ? (
								<li><span className="bold">Email de Paypal:</span> {paypalEmail}</li>
							) : null}
							<li><span className="bold">Concepto:</span> {description}</li>
							<li><span className="bold">Precio:</span> {price}</li>
							<li><span className="bold">IVA:</span> {iva}</li>
							<li><span className="bold">IRPF:</span> {irpf}</li>
							<li><span className="bold">Total:</span> {total}</li>
						</ul>
					</Modal.Body>
					<Modal.Footer>
						{payment === 'pendiente' ? (
							<Button variant="primary" onClick={handleEdit}>
								Editar
							</Button>
						) : 'Factura pagada. Contacta con admin si necesitas editarla.'}

						{ error.status ? <div className="alert alert-danger text-center mt-2" role="alert">{error.msg}</div> : null }
					</Modal.Footer>
				</Modal>
				<Modal show={showEdit} onHide={handleCloseEdit}>
					<Modal.Header closeButton>
						<Modal.Title>Editar {refSupplier}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<form onSubmit={handleSubmit}>
							{ error.status ? <div className="alert alert-danger text-center" role="alert">{error.msg}</div> : null }
							{exito ? <div className="mt-3 alert alert-success text-center registro-exitoso" role="alert">Factura editada correctamente</div> : null }
							<div className="mb-3">
								<div className="form-text">Referencia interna de la factura</div>
								<input 
									type="text" 
									className="form-control" 
									name="ref" 
									onChange={handleChange}
									value={estadoInvoice.ref}
									placeholder="Referencia de la factura" 
								/>
							</div>
							<div className="mb-3">
								<div className="form-text">Fecha de la factura</div>
								<input 
									type="date" 
									className="form-control" 
									name="date" 
									onChange={handleChange}
									value={estadoInvoice.date}
									placeholder="Fecha de la factura" 
								/>
							</div>
							<div className="mb-3">
								<div className="form-text">Fecha de vencimiento</div>
								<input 
									type="date" 
									className="form-control" 
									name="expirationDate" 
									onChange={handleChange}
									value={estadoInvoice.expirationDate}
									placeholder="Fecha de vencimiento" 
								/>
							</div>
							<div className="mb-3">
								<div className="form-text">Número de referencia del proveedor</div>
								<input 
									type="text" 
									className="form-control" 
									name="refSupplier" 
									onChange={handleChange}
									value={estadoInvoice.refSupplier}
									placeholder="Número de referencia del proveedor" 
								/>
							</div>
							<div className="mb-3">
								<div className="form-text">Estado del pago:</div>
								<select className="form-select" name="payment" value={estadoInvoice.payment} onChange={handleChange}>
									<option value="">Estado del pago</option>
									<option value="pendiente">Pendiente</option>
									<option value="pagada">Pagada</option>
								</select>
							</div>
							<div className="mb-3">
								<div className="form-text">Concepto</div>
								<input 
									type="text" 
									className="form-control" 
									name="description" 
									onChange={handleChange}
									value={estadoInvoice.description}
									placeholder="Concepto" 
								/>
							</div>
							<div className="mb-3">
								<div className="form-text">Precio</div>
								<input 
									type="number" 
									className="form-control" 
									name="price" 
									onChange={handleChange}
									value={estadoInvoice.price}
									placeholder="Precio" 
								/>
							</div>
							<div className="mb-3">
								<div className="form-text">IVA</div>
								<input 
									type="number" 
									className="form-control" 
									name="iva" 
									onChange={handleChange}
									value={estadoInvoice.iva}
									placeholder="IVA" 
								/>
							</div>
							<div className="mb-3">
								<div className="form-text">IRPF</div>
								<input 
									type="number" 
									className="form-control" 
									name="irpf" 
									onChange={handleChange}
									value={estadoInvoice.irpf}
									placeholder="IRPF" 
								/>
							</div>
							<div className="mb-3">
							<div className="form-text">Adjuntar archivo (solo pdf permitido):</div>
								<input 
									className="form-control" 
									type="file"
									name="file"
									onChange={handleFile}
									accept="application/pdf"
								/>
							</div>
							<ButtonSubmit text="Enviar" />
						</form>
					</Modal.Body>
					<Modal.Footer>
					</Modal.Footer>
				</Modal>
			</td>
		</tr>
	 );
}
 
export default RowInvoicesOut;
