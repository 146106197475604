import ButtonSubmit from '../ButtonSubmit';
import { Link } from 'react-router-dom';

const FormSaldo = ({handleSubmitSaldo, handleChange, invoice, enterprise, t}) => {

	const { description, price } = invoice;

	return (
		<form onSubmit={handleSubmitSaldo}>
			<p className="text-muted"><span>{t('Rellena los siguientes datos para solicitar el saldo de el/los artículos publicados')}.</span></p>	
			<div className="mb-3">
				<input 
					type="text" 
					className="form-control" 
					name="description" 
					onChange={handleChange}
					value={description}
					placeholder={t('Concepto (nº de pedido + nombre del medio)')}
				/>
			</div>
			<div className="mb-3">
				<input 
					type="number" 
					className="form-control" 
					name="price" 
					onChange={handleChange}
					value={price}
					placeholder={t('Precio')} 
					onWheel={(e) => e.target.blur()}
				/>
			</div>
			<div className="mb-3">
				<div className="form-text mb-2">{t('Forma de pago: (puedes cambiar la forma de pago desde')} <Link to="/profile">{t('tu perfil')}</Link>):</div>
				<select className="form-select" name="payment" value={enterprise.payment} disabled>
					<option value="">{t('Forma de pago')}</option>
					<option value="banco">{t('Transferencia bancaria')}</option>
					<option value="paypal">Paypal</option>
					<option value="saldo">{t('Saldo de Prensalink')}</option>
				</select>
			</div>
			<div className="mb-3">
				<input 
					type="text" 
					className="form-control" 
					name="prensalinkEmail" 
					disabled
					value={enterprise.prensalinkEmail}
					placeholder={t('Email de Prensalink')} 
				/>
			</div> 
			<ButtonSubmit text={t('Enviar factura')} />
		</form>
	)
}

export default FormSaldo
