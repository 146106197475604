import React, { useState, useEffect } from 'react';
import '../css/tableInvoice.css';
import { getUserInvoices } from '../api/invoice';
import RowInvoice from './RowInvoice';
import Pagination from './Pagination';
import { useTranslation } from 'react-i18next';

const TableInvoice = ({token}) => {

	const {t} = useTranslation();

	const [ invoices, setInvoices ] = useState([]);

	const [currentPage, setCurrentPage] = useState(1);
	const [invoicesPerPage] = useState(15);

	useEffect(() => {

		async function fetchDatos() {
			await getUserInvoices(token).then(response => {
				setInvoices(response.invoices);
			});
		}

		fetchDatos();

	}, [token]);

	// Paginar facturas
	const indexOfLastInvoice = currentPage * invoicesPerPage;
	const indexOfFirstInvoice = indexOfLastInvoice - invoicesPerPage;
	const currentInvoice = invoices.slice(indexOfFirstInvoice, indexOfLastInvoice);

	return ( 
		<>
			{invoices.length === 0 ? <span>{t('Todavía no has subido ninguna factura. Pulsa en Nueva factura para reclamar el cobro de los artículos publicados y poder subir tus facturas')}.</span> : (
				<>
					<table className="table">
					<thead className="thead-dark">
						<tr>
							<th scope="col">{t('Factura')}</th>
							<th scope="col">{t('Fecha')}</th>
							<th scope="col">{t('Estado')}</th>
							<th scope="col">{t('Forma de pago')}</th>
							<th scope="col">Total</th>
							<th scope="col"></th>
						</tr>
					</thead>
					<tbody>
						{currentInvoice.map(invoice => (
							<RowInvoice key={invoice._id} invoice={invoice} token={token} />
						))}
					</tbody>
				</table>
				<Pagination
					currentPage={currentPage}
					pageSize={invoicesPerPage} 
					totalCount={invoices.length} 
					onPageChange={page => setCurrentPage(page)}
				/>
			</>
			)}
		</>
	 );
}
 
export default TableInvoice;