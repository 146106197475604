import React, { useState} from 'react';
import '../../css/tableInvoice.css';
import RowSuppliers from './RowSuppliers';
import Pagination from '../Pagination';


const TableSuppliers = ({suppliers, token}) => {

	
	const [currentPage, setCurrentPage] = useState(1);
	const [suppliersPerPage] = useState(25);

	

		// Paginar proveedores
		const indexOfLastSupplier = currentPage * suppliersPerPage;
		const indexOfFirstSupplier = indexOfLastSupplier - suppliersPerPage;
		const currentSupplier = suppliers.slice(indexOfFirstSupplier, indexOfLastSupplier);

	return ( 
		<>
		<table className="table suppliers">
			<thead className="thead-dark">
				<tr>
					<th scope="col">Nombre</th>
					<th scope="col">DNI / CIF</th>
					<th scope="col">País</th>
					<th scope="col">Email</th>
					<th scope="col"></th>
				</tr>
			</thead>
			<tbody>
				{currentSupplier.map(supplier => (
					<RowSuppliers key={supplier._id} supplier={supplier} token={token} />
				))}
			</tbody>
		</table>
		<Pagination 
			currentPage={currentPage}
			pageSize={suppliersPerPage} 
			totalCount={suppliers.length} 
			onPageChange={page => setCurrentPage(page)}
		/>
		</>
	 );
}
 
export default TableSuppliers;