import { useState, useEffect } from 'react'
import ButtonSubmit from '../../components/ButtonSubmit';

const FormsInvoiceOut = ({handleSubmit, handleChange, enterprises, setInvoice, invoice, handleFile, exito, total }) => {

	const [searchTerm, setSearchTerm] = useState("");
	const { supplierID, date, expirationDate, price, refSupplier, description, iva, irpf } = invoice;

	useEffect(() => {
		const filteredEnterprises = enterprises.filter((item) => {
			if (searchTerm === "") {
				return item;
			} else if (
				item.companyName.toLowerCase().includes(searchTerm.toLowerCase()) ||
				item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
				item.surname.toLowerCase().includes(searchTerm.toLowerCase())
			) {
				return item;
			}
		});
	
		// Si solo hay un resultado, actualizar el supplierID automáticamente
		if (filteredEnterprises.length === 1) {
			setInvoice({
				...invoice,
				supplierID: filteredEnterprises[0]._id
			});
		}
	}, [searchTerm, enterprises, invoice]);

	
	return (
		<form onSubmit={handleSubmit}>
			<p className="text-muted"><span>Rellena los siguientes datos para dar de alta una nueva factura de fuera de la plataforma.</span></p>
			<div className="mb-3">
				<div className="form-text mb-2">Proveedor:</div>
				<input
					type="text"
					placeholder="Buscar proveedor..."
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
					className="form-control mb-2 bg-white"
				/>
				<select className="form-select form-control" name="supplierID" value={supplierID} onChange={handleChange}>
					{enterprises
					.filter((item) => {
						if (searchTerm === "") {
						return item;
						} else if (
						item.companyName
							.toLowerCase()
							.includes(searchTerm.toLowerCase()) ||
						item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
						item.surname.toLowerCase().includes(searchTerm.toLowerCase())
						) {
						return item;
						}
					})
					.map((filteredItem) => (
						<option key={filteredItem._id} value={filteredItem._id}>
						{filteredItem.companyName
							? filteredItem.companyName
							: filteredItem.name + " " + filteredItem.surname}
						</option>
					))}
				</select>
			</div>
			<div className="mb-3">
				<div className="form-text">Fecha de emisión:</div>
				<input 
					type="date" 
					className="form-control" 
					name="date" 
					onChange={handleChange}
					value={date}
					placeholder="Fecha de emisión" 
				/>
			</div>
			<div className="mb-3">
				<div className="form-text">Fecha de vencimiento:</div>
				<input 
					type="date" 
					className="form-control" 
					name="expirationDate" 
					onChange={handleChange}
					value={expirationDate}
					placeholder="Fecha de vencimiento" 
				/>
			</div>
			<div className="mb-3">
				<div className="form-text">Número de factura proveedor:</div>
				<input 
					type="text" 
					className="form-control" 
					name="refSupplier" 
					onChange={handleChange}
					value={refSupplier}
					placeholder="A-2024-1" 
				/>
			</div>
			<div className="mb-3">
				<div className="form-text">Concepto:</div>
				<input 
					type="text" 
					className="form-control" 
					name="description" 
					onChange={handleChange}
					value={description}
					placeholder="Descripción de la factura" 
				/>
			</div>
			<div className="mb-3">
				<div className="form-text">Precio neto:</div>
				<input 
					type="number" 
					className="form-control" 
					name="price" 
					onChange={handleChange}
					value={price}
					placeholder="0€"
					onWheel={(e) => e.target.blur()}
				/>
			</div>
			<div className="mb-3">
				<div className="form-text">IVA:</div>
				<input 
					type="number" 
					className="form-control" 
					name="iva" 
					value={iva}
					onChange={handleChange}
					placeholder="0€" 
					onWheel={(e) => e.target.blur()}
				/>
			</div>
			<div className="mb-3">
				<div className="form-text">IRPF:</div>
				<input 
					type="number" 
					className="form-control" 
					name="irpf" 
					value={irpf}
					onChange={handleChange}
					placeholder="0€" 
					onWheel={(e) => e.target.blur()}
				/>
			</div>
			<div className="mb-3">
				<div className="form-text">Total de la factura:</div>
				<input 
					type="number" 
					className="form-control" 
					name="total" 
					disabled
					placeholder={`${total}€`}
				/>
			</div>
			<div className="mb-3">
				<div className="form-text">Adjuntar archivo (solo pdf permitido):</div>
				<input 
					className="form-control" 
					type="file"
					name="file"
					onChange={handleFile}
					accept="application/pdf"
				/>
			</div>
			<ButtonSubmit text="Enviar factura" />
			{exito ? <div className="mt-3 alert alert-success text-center registro-exitoso" role="alert"><p className="mb-0">Factura enviada correctamente.</p></div> : null }
		</form>
	)
}

export default FormsInvoiceOut
