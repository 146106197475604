import { useState } from "react";
import { downloadInvoices, downloadInvoiceZip } from "../../api/invoice";

const DownloadInvoices = ({token}) => {

	const [ form, setForm ] = useState({
		month: ''
	});

	const [exito, setExito ] = useState(false);
	const [error, setError ] = useState(false);

	const { month } = form;

	const handleChange = e => {
		setForm({
			...form,
			[e.target.name] : e.target.value
		})
	};

	const handleSubmit = async e => {

		e.preventDefault();

		if(month === '') {
			setError(true);
		}

		const result = await downloadInvoices(form, token);

		if(result.ok){
			setExito(true);
			downloadInvoiceZip(token);
			setForm({
				month: ''
			});
			setError(false);
		} else {
			setExito(false);
			setError(true); 
		}

	}


	return ( 
		<div className="row mt-3">
			<div className="card">
				<div className="card-header bg-white mt-2">
					<h4>Descargar facturas R-PL por mes</h4>
				</div>
				<div className="card-body">
				{ error ? <div className="alert alert-danger text-center" role="alert">Hubo un error. Comprueba que has seleccionado el mes correctamente.</div> : null }
				{ exito ? <div className="alert alert-success text-center">Descargando facturas...</div> : null }
					<form className="row g-2" onSubmit={handleSubmit}>
						<p className="text-muted">
							Debes indicar el mes que quieres descargar.
						</p>
						<div className="col-auto">
							<select className="form-select" name="month" value={month} onChange={handleChange}>
								<option value="">Mes</option>
								<option value="E">Enero</option>
								<option value="F">Febrero</option>
								<option value="M">Marzo</option>
								<option value="A">Abril</option>
								<option value="MY">Mayo</option>
								<option value="J">Junio</option>
								<option value="JL">Julio</option>
								<option value="AG">Agosto</option>
								<option value="S">Septiembre</option>
								<option value="O">Octubre</option>
								<option value="N">Noviembre</option>
								<option value="D">Diciembre</option>
							</select>
						</div>		
						<div className="col-auto d-flex align-items-center">
							<button type="submit" className="btn btn-primary">Descargar</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	 );
}
 
export default DownloadInvoices;