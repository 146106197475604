import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import ButtonSubmit from "../../ButtonSubmit";

const EditInvoiceModal = ({ showEdit, handleCloseEdit, handleSubmit, invoice, estadoInvoice, handleChange, handleFile, error, exito }) => {

	const { refSupplier, revised } = invoice;

    return (
        <Modal show={showEdit} onHide={handleCloseEdit}>
			<Modal.Header closeButton>
				<Modal.Title>Editar {refSupplier}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form onSubmit={handleSubmit}>
					{ error.status ? <div className="alert alert-danger text-center" role="alert">{error.msg}</div> : null }
					{exito ? <div className="mt-3 alert alert-success text-center registro-exitoso" role="alert">Factura editada correctamente</div> : null }
					{revised ? <div className="mt-3 alert alert-warning text-center" role="alert">La factura ya ha sido revisada. Solo puedes editar el archivo, para más cambios contacta con admin.</div> : null}
					<div className="mb-3">
						<div className="form-text">Referencia interna de la factura</div>
						<input 
							type="text" 
							className="form-control" 
							name="ref" 
							onChange={handleChange}
							value={estadoInvoice.ref}
							placeholder="Referencia de la factura"
							disabled={revised === true} 
						/>
					</div>
					<div className="mb-3">
						<div className="form-text">Fecha de la factura</div>
						<input 
							type="date" 
							className="form-control" 
							name="date" 
							onChange={handleChange}
							value={estadoInvoice.date}
							placeholder="Fecha de la factura" 
							disabled={revised === true}
						/>
					</div>
					<div className="mb-3">
						<div className="form-text">Fecha de vencimiento</div>
						<input 
							type="date" 
							className="form-control" 
							name="expirationDate" 
							onChange={handleChange}
							value={estadoInvoice.expirationDate}
							placeholder="Fecha de vencimiento" 
							disabled={revised === true}
						/>
					</div>
					<div className="mb-3">
						<div className="form-text">Número de referencia del proveedor</div>
						<input 
							type="text" 
							className="form-control" 
							name="refSupplier" 
							onChange={handleChange}
							value={estadoInvoice.refSupplier}
							placeholder="Número de referencia del proveedor" 
							disabled={revised === true}
						/>
					</div>
					<div className="mb-3">
						<div className="form-text">Estado del pago:</div>
						<select className="form-select" name="payment" value={estadoInvoice.payment} onChange={handleChange} disabled={revised === true}>
							<option value="">Estado del pago</option>
							<option value="pendiente">Pendiente</option>
							<option value="pagada">Pagada</option>
						</select>
					</div>
					<div className="mb-3">
						<div className="form-text">Forma de pago:</div>
						<select className="form-select" name="paymentMode" value={estadoInvoice.paymentMode} onChange={handleChange} disabled={revised === true}>
							<option value="">Forma de pago</option>
							<option value="banco">Transferencia bancaria</option>
							<option value="paypal">Paypal</option>
							<option value="saldo">Saldo de Prensalink</option>
						</select>
					</div>
					{ estadoInvoice.paymentMode === 'banco' ? (
							<>
								<div className="mb-3">
									<input 
										type="text" 
										className="form-control" 
										name="accountName" 
										onChange={handleChange}
										value={estadoInvoice.accountName}
										placeholder="Nombre del beneficiario de la transferencia" 
										disabled={revised === true}
									/>
								</div> 
								<div className="mb-3">
									<input 
										type="text" 
										className="form-control" 
										name="iban" 
										onChange={handleChange}
										value={estadoInvoice.iban}
										placeholder="Número de cuenta (IBAN)" 
										disabled={revised === true}
									/>
								</div> 
							</>
							): null}
							{ estadoInvoice.paymentMode === 'paypal' ? (
								<div className="mb-3">
									<input 
										type="text" 
										className="form-control" 
										name="paypalEmail" 
										onChange={handleChange}
										value={estadoInvoice.paypalEmail}
										placeholder="Email de Paypal" 
										disabled={revised === true}
									/>
								</div> 
							): null}
							{ estadoInvoice.paymentMode === 'saldo' ? (
								<div className="mb-3">
									<input 
										type="text" 
										className="form-control" 
										name="prensalinkEmail" 
										onChange={handleChange}
										value={estadoInvoice.prensalinkEmail}
										placeholder="Email de Prensalink" 
										disabled={revised === true}
									/>
								</div> 
							): null}
					<div className="mb-3">
						<div className="form-text">Concepto</div>
						<input 
							type="text" 
							className="form-control" 
							name="description" 
							onChange={handleChange}
							value={estadoInvoice.description}
							placeholder="Concepto" 
							disabled={revised === true}
						/>
					</div>
					<div className="mb-3">
						<div className="form-text">Precio</div>
						<input 
							type="number" 
							className="form-control" 
							name="price" 
							onChange={handleChange}
							value={estadoInvoice.price}
							placeholder="Precio" 
							disabled={revised === true}
						/>
					</div>
					<div className="mb-3">
						<div className="form-text">IVA</div>
						<input 
							type="number" 
							className="form-control" 
							name="iva" 
							onChange={handleChange}
							value={estadoInvoice.iva}
							placeholder="IVA" 
							disabled={revised === true}
						/>
					</div>
					<div className="mb-3">
						<div className="form-text">IRPF</div>
						<input 
							type="number" 
							className="form-control" 
							name="irpf" 
							onChange={handleChange}
							value={estadoInvoice.irpf}
							placeholder="IRPF" 
							disabled={revised === true}
						/>
					</div>
					<div className="mb-3">
						<div className="form-text">Adjuntar archivo (solo pdf permitido):</div>
						<input 
							className="form-control" 
							type="file"
							name="file"
							onChange={handleFile}
							accept="application/pdf"
						/>
					</div>
					<ButtonSubmit text="Enviar" />
				</form>
			</Modal.Body>
			<Modal.Footer>
			</Modal.Footer>
		</Modal>
    );
};

export default EditInvoiceModal;