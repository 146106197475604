import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import RegisterPage from './pages/RegisterPage';
import AuthProvider from './providers/AuthProvider';
import NewProfilePage from './pages/NewProfilePage';
import NewInvoicePage from './pages/NewInvoicePage';
import SuppliersPage from './pages/Admin/SuppliersPage';
import InvoicesPage from './pages/Admin/InvoicesPage';
import Profile from './pages/Profile';
import ReportsPage from './pages/Admin/ReportsPage';
import HelPage from './pages/HelpPage';
import Offers from './pages/OffersPage';
import NewPasswordPage from './pages/NewPasswordPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import InvoicesSupplierPage from './pages/Admin/InvoicesSupplierPage';
import InvoicesOutPage from './pages/Admin/InvoicesOutPage';
import NewInvoiceOutPage from './pages/Admin/NewInvoiceOutPage';
import NewSupplierPage from './pages/Admin/NewSupplierPage';
import ConfigPage from './pages/Admin/ConfigPage';
import OffersAdminPage from './pages/Admin/OffersAdminPage';
import NewInvoicePageAdmin from './pages/Admin/NewInvoicePageAdmin';


function App() {
  return (
	<div className="App">
		<AuthProvider>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<HomePage />} />
					<Route path="/login" element={<LoginPage />} />
					<Route path="/signup" element={<RegisterPage />} />
					<Route path="/new-profile" element={<NewProfilePage />} />
					<Route path="/new-invoice" element={<NewInvoicePage />} />
					<Route path="/profile" element={<Profile />} />
					<Route path="/help" element={<HelPage />} />
					<Route path="/offers" element={<Offers />} />
					<Route path="/forgot-password" element={<ForgotPasswordPage />} />
					<Route path="/new-password/:token" element={<NewPasswordPage />} />
					{/* Admin */}
					<Route path="/admin/suppliers" element={<SuppliersPage />} />
					<Route path="/admin/suppliers/new" element={<NewSupplierPage />} />
					<Route path="/admin/invoices" element={<InvoicesPage />} />
					<Route path="/admin/invoices/new" element={<NewInvoicePageAdmin />} />
					<Route path="/admin/invoices-out" element={<InvoicesOutPage />} />
					<Route path="/admin/invoices-out/new" element={<NewInvoiceOutPage />} />
					<Route path="/admin/invoices/:supplierId" element={<InvoicesSupplierPage />} />
					<Route path="/admin/reports" element={<ReportsPage />} />
					<Route path="/admin/config" element={<ConfigPage />} />
					<Route path="/admin/offers" element={<OffersAdminPage />} />
				</Routes>
			</BrowserRouter>
		</AuthProvider>
	</div>
  );
}

export default App;
