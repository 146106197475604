import React, { useState } from 'react';
import '../../css/tableInvoice.css';
import Pagination from '../Pagination';
import RowInvoicesOut from './RowInvoicesOut';


const TableInvoices = ({invoices, token}) => {

	const [currentPage, setCurrentPage] = useState(1);
	const [invoicesPerPage] = useState(25);


	// Paginar facturas
	const indexOfLastInvoice = currentPage * invoicesPerPage;
	const indexOfFirstInvoice = indexOfLastInvoice - invoicesPerPage;
	const currentInvoice = invoices.slice(indexOfFirstInvoice, indexOfLastInvoice);


	return ( 
		<>
		<table className="table">
			<thead className="thead-dark">
				<tr>
					<th scope="col">Factura</th>
					<th scope="col">Ref.</th>
					<th scope="col">Fecha</th>
					<th scope="col">Estado</th>
					<th scope="col">Método de pago</th>
					<th scope="col">Total</th>
					<th scope="col"></th>
				</tr>
			</thead>
			<tbody>
				{currentInvoice.map(invoice => (
					<RowInvoicesOut key={invoice._id} invoice={invoice} token={token} />
				))}
			</tbody>
		</table>
		<Pagination
			currentPage={currentPage}
			pageSize={invoicesPerPage} 
			totalCount={invoices.length} 
			onPageChange={page => setCurrentPage(page)}
		/>
		</>
	 );
}
 
export default TableInvoices;