import React, { useState, useEffect } from 'react';
import LogoForm from '../../assets/img/logo-form.png';
import '../../css/login.css';
import { getAllEnterprises } from '../../api/enterprise';
import useAuth from '../../hooks/useAuth';
import { Navigate, Link } from 'react-router-dom';
import { getAccessToken } from '../../api/auth';
import { newInvoiceOut } from '../../api/invoice';
import { getMonthInvoiceOut } from '../../api/configAdmin';
import FormsInvoiceOut from '../../components/FormsInvoice/FormsInvoiceOut';

const NewInvoiceOutPage = () => {

	const { user, isLoading } = useAuth();

	const [ ref, setRef ] = useState('');

	const token = getAccessToken();

	const [ invoice, setInvoice ] = useState({
		date: '',
		expirationDate: '',
		refSupplier: '',
		description: '',
		price: '',
		iva: '',
		irpf: '',
		file: '',
		supplierID: ''
	});

	const [ enterprises, setEnterprises ] = useState([]);

	const [ file, setFile ] = useState('');

	const [ total, setTotal ] = useState(0);

	const [ error, setError ] = useState({
		msg: '',
		status: false
	});

	const [ exito, setExito ] = useState(false);

	const { date, expirationDate, refSupplier, description, price, iva, irpf } = invoice;

	const handleChange = e => {
		setInvoice({
			...invoice,
			[e.target.name] : e.target.value
		})
		
	};

	const handleFile = e => {
		setFile(...file, e.target.files[0]);
	}



	useEffect(() => {

		setTotal((Number(price) + Number(iva) - Number(irpf)).toFixed(2));

	}, [price, iva, irpf]);

	useEffect(() => {

		async function fetchDatos() {
			await getAllEnterprises(token).then(response => {
				setEnterprises(response.enterprises);
			});
		}

		fetchDatos();

	}, [token]);

	useEffect(() => {

		async function fetchDatos() {
			await getMonthInvoiceOut(token).then(response => {
				setRef(response.ref);
			});
		}

		fetchDatos();

	}, [token]);

	const handleSubmit = async e => {
		
		e.preventDefault();

		if(date.trim() === '' || expirationDate.trim() === '' || refSupplier.trim() === '' || description.trim() === '' || price.trim() === '') {
			setError({
				msg: 'Alguno de los campos obligatorios no están rellenados.',
				status: true
			});
			return;
		}

		if(!file) {
			setError({
				msg: 'Debes adjuntar la factura para poder darla de alta.',
				status: true
			});
			return;
		} 

		if (date > expirationDate) {
			setError({
				msg: 'La fecha de vencimiento no puede ser previa a la fecha de emisión.',
				status: true
			});
			return;
		}

		if (date > datePermited(ref)) {
			setError({
				msg: 'Solo se pueden subir facturas de este mes o los anteriores. Contacta con soporte.',
				status: true
			});
			return;
		}
		
		invoice.file = file;

		const result = await newInvoiceOut(invoice, token);
	

	if(!result.ok) {
			setError({
				msg: result.message,
				status: true
			});
			setExito(false);
			
		} else {
			setError({
				msg: "",
				status: false
			});
			setInvoice({
				date: '',
				expirationDate: '',
				refSupplier: '',
				description: '',
				price: '',
				iva: '',
				irpf: '',
				file: '',
				supplierID: ''
			});
			setExito(true);
		}
	}

	if (!user && isLoading) return 'Cargando...';

	if(!user && !isLoading) {
		return (
			<Navigate to="/login" />
		)
	}

	if (user && user.role !== 'admin') {
		return (
			<Navigate to="/" />
		)
	}
	
	return ( 
		<div className="d-flex justify-content-center align-items-center fullpage">
			<div className="d-flex flex-column align-items-center mt-4">
				<img src={LogoForm} alt="Logo" className="logo-login mb-4 px-3" />
				<div className="card fixed-width">
					<div className="card-body">
						{ error.status ? <div className="alert alert-danger text-center" role="alert">{error.msg}</div> : null }
						<FormsInvoiceOut
							handleSubmit={handleSubmit}
							enterprises={enterprises}
							invoice={invoice}
							handleChange={handleChange}
							handleFile={handleFile}
							total={total}
							exito={exito} 
							setInvoice={setInvoice}
						/>
					</div>
				</div>
				<div className="my-3">
					<Link className="col-7" to="/admin/invoices-out">
						<span>Volver al listado de facturas</span>
					</Link>
				</div>
			</div>
			
		</div>
	 );
}
 
export default NewInvoiceOutPage;

const datePermited = (refMonth) => {

	if (refMonth === 'E') {
		return '2024-01-31'
	} else if (refMonth === 'F') {
		return '2024-02-28'
	} else if (refMonth === 'M') {
		return '2024-03-31'
	} else if (refMonth === 'A') {
		return '2024-04-30'
	} else if (refMonth === 'MY') {
		return '2024-05-31'
	} else if (refMonth === 'J') {
		return '2024-06-30'
	} else if (refMonth === 'JL') {
		return '2024-07-31'
	} else if (refMonth === 'AG') {
		return '2024-08-31'
	} else if (refMonth === 'S') {
		return '2024-09-30'
	} else if (refMonth === 'O') {
		return '2024-10-31'
	} else if (refMonth === 'N') {
		return '2024-11-30'
	} else if (refMonth === 'D') {
		return '2024-12-31'
	} 
	
}