import { basePath } from './config';

export async function newInvoice(data, token) {

	const formData = new FormData();
	Object.keys(data).forEach((key) => {
		formData.append(key, data[key])
	});
	//console.log(data);
	formData.append("file", data.file);
	/* for (var pair of formData.entries()) {
		console.log(pair[0]+ ', ' + pair[1]); 
	} */

  const url = `${basePath}/api/invoices/new`;
  const params = {
    method: "POST",
    body: formData,
    headers: {
		"Authorization": token
    }
  };

  return await fetch(url, params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      if (result.invoice) {
        return { ok: true, message: "Factura creada correctamente" };
      }
      return { ok: false, message: result.msg };
    })
    .catch(err => {
      return { ok: false, message: err.msg };
    });
};

export async function getUserInvoices(token) {
	const url = `${basePath}/api/invoices/user-invoices`;
	const params = {
	  method: "GET",
	  body: JSON.stringify(),
	  headers: {
		"Content-Type": "application/json",
		"Authorization": token
	  }
	};
  
	return await fetch(url, params)
		.then(response => {
			return response.json();
		})
		.then(result => {
			return result;
		})
		.catch(err => {
			return err.message;
		})
  };

  export async function getAllInvoices(token, terminoBusqueda = "") {
	const url = `${basePath}/api/invoices/${terminoBusqueda ? `?busqueda=${encodeURIComponent(terminoBusqueda)}` : ''}`;
	const params = {
	  method: "GET",
	  headers: {
		"Content-Type": "application/json",
		"Authorization": token
	  }
	};
   
	return await fetch(url, params)
		.then(response => {
			return response.json();
		})
		.then(result => {
			return result;
		})
		.catch(err => {
			return err.message;
		})
};

export async function updateInvoice(data, token) {

	const formData = new FormData();
	Object.keys(data).forEach((key) => {
		formData.append(key, data[key])
	});
	formData.append("file", data.file);

	const url = `${basePath}/api/invoices/edit`;
	const params = {
	  method: "POST",
	  body: formData,
	  headers: {
		"Authorization": token
	  }
	}; 
  
	return await fetch(url, params)
	  .then(response => {
		return response.json();
	  })
	  .then(result => {
		if (result.invoice) {
		  return { ok: true, message: "Factura editada correctamente" };
		}
		return { ok: false, message: result.message };
	  })
	  .catch(err => {
		return { ok: false, message: err.message };
	  });
  };

  export async function revisedInvoice(id, token) {

	const data = {
		_id: id
	}
	const url = `${basePath}/api/invoices/revised`;
	const params = {
	  method: "POST",
	  body: JSON.stringify(data),
	  headers: {
		"Content-Type": "application/json",
		"Authorization": token
	  }
	};
  
	return await fetch(url, params)
	  .then(response => {
		return response.json();
	  })
	  .then(result => {
		if (result.invoice) {
		  return { ok: true, message: "Factura revisada correctamente" };
		}
		return { ok: false, message: result.message };
	  })
	  .catch(err => {
		return { ok: false, message: err.message };
	  });
  };

  export async function getInvoicesById(id,token) {
	const url = `${basePath}/api/invoices/${id}`;
	const params = {
	  method: "GET",
	  body: JSON.stringify(),
	  headers: {
		"Content-Type": "application/json",
		"Authorization": token
	  }
	};
  
	return await fetch(url, params)
		.then(response => {
			return response.json();
		})
		.then(result => {
			return result;
		})
		.catch(err => {
			return err.message;
		})
  };

  export async function downloadInvoices(data, token) {
	const url = `${basePath}/api/invoices/download`;
	const params = {
	  method: "POST",
	  body: JSON.stringify(data),
	  headers: {
		"Content-Type": "application/json",
		"Authorization": token
	  }
	};
  
	return await fetch(url, params)
	  .then(response => {
		return response.json();
	  })
	  .then(result => {
		if (result.ok) {
		  return { ok: true, message: "Zip creado correctamente" };
		}
		return { ok: false, message: result.message };
	  })
	  .catch(err => {
		return { ok: false, message: err.message };
	  });
  };

  export async function downloadInvoiceZip(token) {
	const url = `${basePath}/api/invoices/download-zip`;
	const params = {
	  method: "GET",
	  headers: {
		"Content-Type": "application/json",
		"Authorization": token
	  }
	};
  
	return await fetch(url, params)
	  .then(response => {
		return response.blob();
	  })
	  .then(blob => {
		const link = document.createElement("a");
    	link.href = URL.createObjectURL(blob);
    	link.setAttribute("download", "invoices.zip");
    	document.body.appendChild(link);
    	link.click();
	  })
	  .catch(err => {
		return { ok: false, message: err.msg };
	  });
  };

  export async function deleteInvoice(id, token) {

	const data = {
		_id: id
	}
	const url = `${basePath}/api/invoices/`;
	const params = {
	  method: "DELETE",
	  body: JSON.stringify(data),
	  headers: {
		"Content-Type": "application/json",
		"Authorization": token
	  }
	};
  
	return await fetch(url, params)
	  .then(response => {
		return response.json();
	  })
	  .then(result => {
		if (result) {
		  return { ok: true, message: "Factura eliminada correctamente" };
		}
		return { ok: false, message: result.message };
	  })
	  .catch(err => {
		return { ok: false, message: err.message };
	  });
  };

  // Invoices Out

  export async function newInvoiceOut(data, token) {

	const formData = new FormData();
	Object.keys(data).forEach((key) => {
		formData.append(key, data[key])
	});
	formData.append("file", data.file);

  const url = `${basePath}/api/invoices-out/new`;
  const params = {
    method: "POST",
    body: formData,
    headers: {
		"Authorization": token
    }
  };

  return await fetch(url, params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      if (result.invoice) {
        return { ok: true, message: "Factura creada correctamente" };
      }
      return { ok: false, message: result.msg };
    })
    .catch(err => {
      return { ok: false, message: err.msg };
    });
};

export async function getAllInvoicesOut(token, terminoBusqueda = "") {
	const url = `${basePath}/api/invoices-out/${terminoBusqueda ? `?busqueda=${encodeURIComponent(terminoBusqueda)}` : ''}`;
	const params = {
	  method: "GET",
	  headers: {
		"Content-Type": "application/json",
		"Authorization": token
	  }
	};
  
	return await fetch(url, params)
		.then(response => {
			return response.json();
		})
		.then(result => {
			return result;
		})
		.catch(err => {
			return err.message;
		})
};

  export async function updateInvoiceOut(data, token) {

	const formData = new FormData();
	Object.keys(data).forEach((key) => {
		formData.append(key, data[key])
	});
	formData.append("file", data.file);

	const url = `${basePath}/api/invoices-out/edit`;
	const params = {
	  method: "POST",
	  body: formData,
	  headers: {
		"Authorization": token
	  }
	};
  
	return await fetch(url, params)
	  .then(response => {
		return response.json();
	  })
	  .then(result => {
		if (result.invoice) {
		  return { ok: true, message: "Factura editada correctamente" };
		}
		return { ok: false, message: result.message };
	  })
	  .catch(err => {
		return { ok: false, message: err.message };
	  });
  };

  export async function getInvoicesOutById(id,token) {
	const url = `${basePath}/api/invoices-out/${id}`;
	const params = {
	  method: "GET",
	  body: JSON.stringify(),
	  headers: {
		"Content-Type": "application/json",
		"Authorization": token
	  }
	};
  
	return await fetch(url, params)
		.then(response => {
			return response.json();
		})
		.then(result => {
			return result;
		})
		.catch(err => {
			return err.message;
		})
  };

  export async function downloadInvoicesOut(data, token) {
	const url = `${basePath}/api/invoices-out/download`;
	const params = {
	  method: "POST",
	  body: JSON.stringify(data),
	  headers: {
		"Content-Type": "application/json",
		"Authorization": token
	  }
	};
  
	return await fetch(url, params)
	  .then(response => {
		return response.json();
	  })
	  .then(result => {
		if (result.ok) {
		  return { ok: true, message: "Zip creado correctamente" };
		}
		return { ok: false, message: result.message };
	  })
	  .catch(err => {
		return { ok: false, message: err.message };
	  });
  };

  export async function downloadInvoiceZipOut(token) {
	const url = `${basePath}/api/invoices-out/download-zip`;
	const params = {
	  method: "GET",
	  headers: {
		"Content-Type": "application/json",
		"Authorization": token
	  }
	};
  
	return await fetch(url, params)
	  .then(response => {
		return response.blob();
	  })
	  .then(blob => {
		const link = document.createElement("a");
    	link.href = URL.createObjectURL(blob);
    	link.setAttribute("download", "invoicesOut.zip");
    	document.body.appendChild(link);
    	link.click();
	  })
	  .catch(err => {
		return { ok: false, message: err.msg };
	  });
  };
