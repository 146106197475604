import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './i18n/en.json';
import es from './i18n/es.json';

i18next
.use(LanguageDetector)
.use(initReactI18next)
.init({
	fallbackLng: 'es',
	interpolation:{
		escapeValue:false
	},
	resources:{
		en:{
			translation: en
		},
		es: {
			translation: es
		}
	}
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
