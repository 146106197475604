import '../css/breadcrumb.css';
import { useTranslation } from 'react-i18next';

const Breadcrumbs = ({currentPage}) => {

	const {t} = useTranslation();

	return ( 
		<nav>
			<ul className="breadcrumb py-4">
				<li className="breadcrumb-item">
					<a href="/">{t('Inicio')}</a>
				</li>
				<li className="active breadcrumb-item">{currentPage}</li>
			</ul>
		</nav>
	 );
}
 
export default Breadcrumbs;