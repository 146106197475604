import React, { useState} from 'react';
import Pagination from '../Pagination';
import OfferRow from './OfferRow';


const OffersTable = ({offers, token}) => {

	const [currentPage, setCurrentPage] = useState(1);
	const [offersPerPage] = useState(25);


	// Paginar facturas
	const indexOfLastOffers = currentPage * offersPerPage;
	const indexOfFirstOffer = indexOfLastOffers - offersPerPage;
	const currentOffer = offers.slice(indexOfFirstOffer, indexOfLastOffers);


	return ( 
		<>
		<table className="table">
			<thead className="thead-dark">
				<tr>
					<th scope="col">Medio</th>
					<th scope="col">Inicio</th>
					<th scope="col">Fin</th>
					<th scope="col text-center">Nuevo precio</th>
					<th scope="col">Estado</th>
					<th scope="col"></th>
				</tr>
			</thead>
			<tbody>
				{currentOffer.map(offer => (
					<OfferRow key={offer._id} offer={offer} token={token} />
				))}
			</tbody>
		</table>
		<Pagination
			currentPage={currentPage}
			pageSize={offersPerPage} 
			totalCount={offers.length} 
			onPageChange={page => setCurrentPage(page)}
		/>
		</>
	 );
}
 
export default OffersTable;