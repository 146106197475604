import React, { useState, useEffect } from 'react';
import '../../css/tableInvoice.css';
import { getAccessToken } from '../../api/auth';
import RowInvoices from './RowInvoices';
import Pagination from '../Pagination';
import { getInvoicesById, getInvoicesOutById } from '../../api/invoice';

const TableInvoicesSingleUser = ({supplierId}) => {

	const [ invoices, setInvoices ] = useState([]);

	const [currentPage, setCurrentPage] = useState(1);
	const [invoicesPerPage] = useState(25);

	const token = getAccessToken();


	useEffect(() => {

		async function fetchDatos() {
			await getInvoicesById(supplierId,token)
			.then(async function (response){
				if(response.invoices.length !== 0){
					setInvoices(response.invoices);
				} else {
					await getInvoicesOutById(supplierId,token).then(response => {
						setInvoices(response.invoices);
					})
				}
				
			});
		}

		fetchDatos();
		

	}, [token]);

	
	
	// Paginar facturas
	const indexOfLastInvoice = currentPage * invoicesPerPage;
	const indexOfFirstInvoice = indexOfLastInvoice - invoicesPerPage;
	const currentInvoice = invoices.slice(indexOfFirstInvoice, indexOfLastInvoice);


	return ( 
		<>
		<table className="table">
			<thead className="thead-dark">
				<tr>
					<th scope="col">Factura</th>
					<th scope="col">Ref.</th>
					<th scope="col">Nombre</th>
					<th scope="col">Fecha</th>
					<th scope="col">Estado</th>
					<th scope="col">Método de pago</th>
					<th scope="col">Total</th>
					<th scope="col"></th>
				</tr>
			</thead>
			<tbody>
				{currentInvoice.map(invoice => (
					<RowInvoices key={invoice._id} invoice={invoice} token={token} />
				))}
			</tbody>
		</table>
		<Pagination
			currentPage={currentPage}
			pageSize={invoicesPerPage} 
			totalCount={invoices.length} 
			onPageChange={page => setCurrentPage(page)}
		/>
		</>
	);
}
 
export default TableInvoicesSingleUser;