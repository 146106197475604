import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';
import { deleteInvoice } from "../../api/invoice";

const DeleteInvoice = ({_id, token, setError, refNumber}) => {

	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const handleDelete = async() => {

		const result = await deleteInvoice(_id, token);

		if (result.ok) {

			setTimeout(function() { 
				window.location.reload();
			}, 400);

		} else {
			setError({		
				msg: result.message,
				status: true
			});
		}

	}

	return(
		<>
			<Button variant="danger" className="px-2 py-0" onClick={handleShow}>
				<Trash />
			</Button>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<h4>Eliminar factura {refNumber}</h4>
				</Modal.Header>
				<Modal.Body className="py-4 px-4">
					<p className="mb-4">¿Estás seguro de que quieres borrar esta factura?</p>
					<Button variant="danger" onClick={handleDelete}>
						Eliminar factura
					</Button>
					<Button variant="secondary" className="mx-2" onClick={handleClose}>
						Volver
					</Button>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default DeleteInvoice;