import React from 'react';
import { usePagination, DOTS } from './usePagination';

const Pagination = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
	onPageChange(currentPage + 1);
	window.scrollTo(0, 0); 
  };

  const onPrevious = () => {
	onPageChange(currentPage - 1);
	window.scrollTo(0, 0); 
  };
  

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul
      className='pagination justify-content-center mt-4'
    >
       {/* Left navigation arrow */}
	{	currentPage === 1 ?
	   	 <li className="page-item disabled">
			<div className="arrow left page-link">«</div>
		  </li> :  
	   	<li
	   		className="page-item"
	   		onClick={onPrevious}
	 	>
	   		<div className="arrow left page-link">«</div>
	 	</li>
	}

      {paginationRange.map(pageNumber => {
         
        // If the pageItem is a DOT, render the DOTS unicode character
        if (pageNumber === DOTS) {
          return <li className="page-item dots px-3">&#8230;</li>;
        }
		
        // Render our Page Pills
        return (
          <li
		  	className="page-item"
            onClick={() => {onPageChange(pageNumber);window.scrollTo(0, 0);}}
          >
            <button className={`page-link ${currentPage === pageNumber ? 'active' : null }`}>{pageNumber}</button>
          </li>
        );
      })}
      {/*  Right Navigation arrow */}
	  {	currentPage === lastPage ?
	   	 <li className="page-item disabled">
			<div className="arrow right page-link">»</div>
		  </li> :  
	   	<li
	   		className="page-item"
	   		onClick={onNext}
	 	>
	   		<div className="arrow right page-link">»</div>
	 	</li>
	}
    </ul>
  );
};

export default Pagination;