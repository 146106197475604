import React, { useState } from 'react';
import LogoForm from '../../assets/img/logo-form.png';
import '../../css/login.css';
import ButtonSubmit from '../../components/ButtonSubmit';
import { Link, Navigate } from 'react-router-dom';
import { newEnterpriseAdmin } from '../../api/enterprise';
import useAuth from '../../hooks/useAuth';
import { getAccessToken } from '../../api/auth';

const NewSupplierPage = () => {

	const { user, isLoading } = useAuth();

	const token = getAccessToken();

	const [ profile, setProfile ] = useState({
		name: '',
		surname: '',
		email: '',
		dni: '',
		companyName: '',
		address: '',
		cp: '',
		city: '',
		country: '',
		phone: '',
		payment: '',
		accountName: '',
		iban: '',
		paypalEmail: '',
		prensalinkEmail: '',
		iva: '',
		irpf: ''
	});

	const [ error, setError ] = useState({
		msg: '',
		status: false
	});

	const [ exito, setExito ] = useState(false);


	const { name, surname, dni, email, companyName, accountName, paypalEmail, iban, iva, irpf, address, cp, city, country, phone, payment } = profile;

	const handleChange = e => {
		setProfile({
			...profile,
			[e.target.name] : e.target.value
		})
	}; 

	const handleSubmit = async e => {
		e.preventDefault();

		if(name.trim() === '' || email.trim() ==='' || surname.trim() === '' || dni.trim() === '' || address.trim() === '' || cp.trim() === '' || city.trim() === '' || country.trim() === '' || phone.trim() === '' || payment === '' || iva === '' || irpf === '') {
			setError({
				msg: 'Alguno de los campos obligatorios no están rellenados.',
				status: true
			});
			setExito(false);
			return;
		}

		const result = await newEnterpriseAdmin(profile, token);

		if(!result.ok) {
			setError({
				msg: result.message,
				status: true
			});
			setExito(false);
			
		} else {
			setError({
				msg: "",
				status: false
			});
			setProfile({
				name: '',
				surname: '',
				dni: '',
				companyName: '',
				address: '',
				cp: '',
				city: '',
				country: '',
				phone: '',
				payment: '',
				accountName: '',
				iban: '',
				paypalEmail: '',
				prensalinkEmail: '',
				iva: '',
				irpf: '',
				email: ''
			});
			setExito(true);
		}

	}

	if (!user && isLoading) return 'Cargando...';

	if(!user && !isLoading) {
		return (
			<Navigate to="/login" />
		)
	}

	if (user && user.role !== 'admin') {
		return (
			<Navigate to="/" />
		)
	}


	return ( 
		<div className="d-flex justify-content-center align-items-center fullpage">
			<div className="d-flex flex-column align-items-center">
				<img src={LogoForm} alt="Logo" className="logo-login mb-4 px-3" />
				<div className="card w-100">
					<div className="card-body">
						{ error.status ? <div className="alert alert-danger text-center" role="alert">{error.msg}</div> : null }
						<form onSubmit={handleSubmit}>
							<p className="text-muted"><span>Rellena los siguientes datos para crear tu perfil de facturación y poder adjuntar las facturas.</span></p>
							<div className="mb-3">
								<input 
									type="text" 
									className="form-control" 
									name="name" 
									onChange={handleChange}
									value={name}
									placeholder="Nombre" 
								/>
							</div>
							<div className="mb-3">
								<input 
									type="text" 
									className="form-control" 
									name="surname" 
									onChange={handleChange}
									value={surname}
									placeholder="Apellidos" 
								/>
							</div>
							<div className="mb-3">
								<input 
									type="text" 
									className="form-control" 
									name="companyName" 
									onChange={handleChange}
									value={companyName}
									placeholder="Nombre de la empresa (opcional)" 
								/>
							</div>
							<div className="mb-3">
								<input 
									type="text" 
									className="form-control" 
									name="dni" 
									onChange={handleChange}
									value={dni}
									placeholder="DNI / CIF(empresas)" 
								/>
							</div>
							<div className="mb-3">
								<input 
									type="email"  
									className="form-control" 
									name="email" 
									onChange={handleChange}
									value={email}
									placeholder="correo@correo.com" 
								/>
							</div>
							<div className="mb-3">
								<select className="form-select" name="payment" value={payment} onChange={handleChange}>
									<option value="">Forma de pago</option>
									<option value="banco">Transferencia bancaria</option>
									<option value="paypal">Paypal</option>
									<option value="giro">Giro bancario</option>
									<option value="mastercard">Tarjeta Mastercard</option>
									<option value="amex">Tarjeta AMEX</option>
								</select>
							</div>
							{ payment === 'banco' ? (
							<>
								<div className="mb-3">
									<input 
										type="text" 
										className="form-control" 
										name="accountName" 
										onChange={handleChange}
										value={accountName}
										placeholder="Nombre del beneficiario de la transferencia" 
									/>
								</div> 
								<div className="mb-3">
									<input 
										type="text" 
										className="form-control" 
										name="iban" 
										onChange={handleChange}
										value={iban}
										placeholder="Número de cuenta (IBAN)" 
									/>
								</div> 
							</>
							): null}
							{ payment === 'paypal' ? (
								<div className="mb-3">
									<input 
										type="text" 
										className="form-control" 
										name="paypalEmail" 
										onChange={handleChange}
										value={paypalEmail}
										placeholder="Email de Paypal" 
									/>
								</div> 
							): null}
								<div className="mb-3">
									<input 
										type="number" 
										className="form-control" 
										name="irpf" 
										onChange={handleChange}
										value={irpf}
										placeholder="% de IRPF" 
									/>
								</div>
								<div className="mb-3">
									<input 
										type="number" 
										className="form-control" 
										name="iva" 
										onChange={handleChange}
										value={iva}
										placeholder="% de IVA" 
									/>
								</div>
							<div className="mb-3">
								<input 
									type="text" 
									className="form-control" 
									name="address" 
									onChange={handleChange}
									value={address}
									placeholder="Dirección de facturación" 
								/>
							</div>
							<div className="mb-3">
								<input 
									type="text" 
									className="form-control" 
									name="cp" 
									onChange={handleChange}
									value={cp}
									placeholder="Código Postal" 
								/>
							</div>
							<div className="mb-3">
								<input 
									type="text" 
									className="form-control" 
									name="city" 
									onChange={handleChange}
									value={city}
									placeholder="Localidad" 
								/>
							</div>
							<div className="mb-3">
								<input 
									type="text" 
									className="form-control" 
									name="country" 
									onChange={handleChange}
									value={country}
									placeholder="País" 
								/>
							</div>
							<div className="mb-3">
								<input 
									type="text" 
									className="form-control" 
									name="phone" 
									onChange={handleChange}
									value={phone}
									placeholder="Teléfono" 
								/>
							</div>
							<ButtonSubmit text="Enviar" />
						</form>
						{exito ? <div className="mt-3 alert alert-success text-center registro-exitoso" role="alert">Perfil de facturación creado correctamente.</div> : null }
					</div>
				</div>
				<div className="my-3">
					<Link className="col-7" to="/admin/suppliers">
						<span>Volver al listado de proveedores</span>
					</Link>
				</div>
			</div>
		</div>
	 );
}
 
export default NewSupplierPage;