import { basePath } from './config';

export async function newOffer(data, token) {
  const url = `${basePath}/api/offer/new`;
  const params = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
	  "Content-Type": "application/json",
	  "Authorization": token
    }
  };

  return await fetch(url, params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      if (result.offer) {
        return { ok: true, message: "Oferta enviada correctamente" };
      }
      return { ok: false, message: result.msg };
    })
    .catch(err => {
      return { ok: false, message: err.msg };
    });
};

export async function getAllOffers(token) {
	const url = `${basePath}/api/offer/`;
	const params = {
	  method: "GET",
	  body: JSON.stringify(),
	  headers: {
		"Content-Type": "application/json",
		"Authorization": token
	  }
	};
  
	return await fetch(url, params)
		.then(response => {
			return response.json();
		})
		.then(result => {
			return result;
		})
		.catch(err => {
			return err.message;
		})
};

export async function updateOffer(data, token) {

	const url = `${basePath}/api/offer/edit`;
	const params = {
	  method: "PUT",
	  body: JSON.stringify(data),
	  headers: {
		"Content-Type": "application/json",
		"Authorization": token
	  }
	}; 

	return await fetch(url, params)
	  .then(response => {
		return response.json();
	  })
	  .then(result => {
		if (result.offer) {
		  return { ok: true, message: "Oferta actualizada correctamente" };
		}
		return { ok: false, message: result.message };
	  })
	  .catch(err => {
		return { ok: false, message: err.message };
	  });
};