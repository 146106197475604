import { useState, useEffect } from "react";
import { getUserEnterpriseById } from "../../api/enterprise";
import Paypal from '../../assets/img/paypal-icon.svg';
import Saldo from '../../assets/img/logo-nav.svg';
import Bank from '../../assets/img/bank.svg';
import { Button } from 'react-bootstrap';
import { EyeFill, PencilFill } from 'react-bootstrap-icons';
import { updateInvoice, revisedInvoice } from "../../api/invoice";
import moment from 'moment';
import { Link } from 'react-router-dom';
import DeleteInvoice from "./DeleteInvoice";
import { ExclamationOctagonFill } from 'react-bootstrap-icons';
import ViewInvoiceModal from "./Invoices/ViewInvoiceModal";
import EditInvoiceModal from "./Invoices/EditInvoiceModal";

const paymentModeFn = (payment) => {

	if(payment === 'banco') {
		return (
			<td className="text-nowrap">
				<img src={Bank} className="logo-money" alt="Transferencia" />
				<span>Transferencia</span>
			</td>)
	} else if (payment === 'saldo') {
		return (
			<td className="text-nowrap">
				<img src={Saldo} className="logo-money" alt="Saldo" />
				<span>Saldo</span>
			</td>)
	} else if (payment === 'paypal') {
		return (
			<td className="text-nowrap"> 
				<img src={Paypal} className="logo-money" alt="Paypal" />
				<span>Paypal</span>
			</td>)
	} else if (payment === 'giro') {
		return (
			<td className="text-nowrap"> 
				<img src={Bank} className="logo-money" alt="giro" />
				<span>Giro bancario</span>
			</td>)
	} else {
		return ( 
			<td className="text-nowrap">
				<span>N/A</span>
			</td>)
	};
	
}


const RowInvoices = ({invoice, token}) => {

	const [ supplier, setSupplier ] = useState([]);

	const { _id, date, total, payment, ref, paymentMode, revised, refSupplier } = invoice;

	const [ estadoInvoice, setEstadoInvoice ] = useState({
		_id: invoice._id || '',
		date: invoice.date || '',
		expirationDate: invoice.expirationDate || '',
		refSupplier: invoice.refSupplier || '',
		description: invoice.description || '',
		price: invoice.price || 0,
		iva: invoice.iva || 0,
		irpf: invoice.irpf || 0,
		payment: invoice.payment || '',
		ref: invoice.ref || '',
		paymentMode: invoice.paymentMode || '',
		accountName: invoice.accountName || '',
		iban: invoice.iban || '',
		paypalEmail: invoice.paypalEmail || '',
		prensalinkEmail: invoice.prensalinkEmail || '',
		file: ''
	})

	const [show, setShow] = useState(false);
	const [verificada, setVerificada] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const [showEdit, setShowEdit] = useState(false);

	const handleCloseEdit = () => setShowEdit(false);

	const [ exito, setExito ] = useState(false);
	const [ error, setError ] = useState({
		msg: '',
		status: false
	});

	const [ file, setFile ] = useState('');

	const handleEdit = () => {
		setShowEdit(true);
		setShow(false)
	}

	useEffect(() => {

		async function fetchDatos() {
			await getUserEnterpriseById(invoice.supplier,token).then(response => {
				setSupplier(response.enterprise);
			});
		}

		fetchDatos();

	}, [token, invoice.supplier]);

	const handleChange = e => {
		setEstadoInvoice({
			...estadoInvoice,
			[e.target.name] : e.target.value
		})
	}

	const handleFile = e => {
		setFile(...file, e.target.files[0]);
	}

	const handleSubmit = async e => {
		
		e.preventDefault();

		if(estadoInvoice.date.trim() === '' || estadoInvoice.expirationDate.trim() === '' || estadoInvoice.price === '' || estadoInvoice.refSupplier.trim() === '' || estadoInvoice.iva === '' || estadoInvoice.irpf === '' || estadoInvoice.description.trim() === '' || estadoInvoice.payment === '') {
			setError({
				msg: 'Alguno de los campos obligatorios no están rellenados.',
				status: true
			});
			setExito(false);
			return;
		}

		if(estadoInvoice.date > estadoInvoice.expirationDate) {
			setError({
				msg: 'La fecha de vencimiento no puede ser previa a la fecha de emisión.',
				status: true
			});
			setExito(false);
			return;
		}

		if(file) {
			estadoInvoice.file = file;
		}

		const result = await updateInvoice(estadoInvoice, token);

		if (result.ok) {
			setExito(true);
			setError({
				msg: '',
				status: false
			});
			setTimeout(function() { 
				setShow(false);
			}, 400);
		} else {
			setError({		
				msg: result.message,
				status: true
			});
			setExito(false);
			setEstadoInvoice({
				_id: invoice._id || '',
				date: invoice.date || '',
				expirationDate: invoice.expirationDate || '',
				refSupplier: invoice.refSupplier || '',
				description: invoice.description || '',
				price: invoice.price || 0,
				iva: invoice.iva || 0,
				irpf: invoice.irpf || 0,
				payment: invoice.payment || '',
				ref: invoice.ref || '',
				paymentMode: invoice.paymentMode || '',
				accountName: invoice.accountName || '',
				iban: invoice.iban || '',
				paypalEmail: invoice.paypalEmail || '',
				prensalinkEmail: invoice.prensalinkEmail || '',
				file: ''
			});
		}
	}

	const handleRevised = async () => {

		const result = await revisedInvoice(_id, token);

		if (result.ok) {
			setVerificada(true);
		} else {
			setError({		
				msg: result.message,
				status: true
			});
		}

	}

	const checkRefInvoice = (ref) => {
		const refString = new String(ref.split('-')[1]);
	
		if (refString.charAt(0) === 'A') {
			return true
		} else {
			return false
		}
	}

	return ( 
		<tr className={`${revised ? 'bg-revised' : 'bg-white'} my-2`}>
			<td className="bold text-nowrap">{ref}</td>
			<td className="text-nowrap">{refSupplier}</td>
			<td className="text-truncate">
				<Link to={`/admin/invoices/${supplier._id}`} className="table-link">
					{ supplier.companyName ? supplier.companyName : supplier.name + ' ' + supplier.surname}
				</Link>
			</td>
			<td className="text-nowrap">{moment(date).format("DD/MM/YYYY")}</td>
			<td className="text-nowrap">
				{payment ==='pendiente' ? <span className="badge text-bg-danger">Pendiente</span> : <span className="badge text-bg-success">Pagada</span> }
			</td>
			{paymentModeFn(paymentMode)}
			<td className="text-nowrap">{supplier.onOffer && <ExclamationOctagonFill className="mx-1 text-danger" />}{total} €</td>
			<td className="text-right text-nowrap">
				<div>
					{paymentMode === 'saldo' ? null : (
						<a className="btn btn-primary px-2 py-0" target="_blank" rel="noreferrer" href={`https://api-proveedores.prensalink.com/${checkRefInvoice(ref) ? `invoices-out/${ref}` : `invoices/${ref}` }.pdf`}>
							<EyeFill />
						</a>
					)}
					<Button variant="warning" className="px-2 py-0 mx-1" onClick={handleShow}>
						<PencilFill />
					</Button>
					<DeleteInvoice _id={_id} token={token} setError={setError} refNumber={ref} />
					
				</div>
				
				<ViewInvoiceModal 
					show={show} 
					handleClose={handleClose}  
					handleEdit={handleEdit} 
					supplier={supplier} 
					handleRevised={handleRevised} 
					verificada={verificada} 
					error={error}
					invoice={invoice}
				/>	
				<EditInvoiceModal 
					showEdit={showEdit} 
					handleCloseEdit={handleCloseEdit}
					handleSubmit={handleSubmit} 
					estadoInvoice={estadoInvoice} 
					handleChange={handleChange} 
					handleFile={handleFile} 
					error={error} 
					exito={exito}
					invoice={invoice}
				/>
			</td>
		</tr>
	 );
}
 
export default RowInvoices;