import React, { useState } from 'react';
import ButtonSubmit from "../components/ButtonSubmit";
import LogoForm from '../assets/img/logo-form.png';
import { useParams } from 'react-router-dom';
import { newPasswordApi } from '../api/user';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const NewPasswordPage = () => {

	const {t} = useTranslation();

	const [ input, setInput ] = useState({
		password: ''
	});

	const { password } = input;

	const [ error, setError ] = useState('');
	const [ exito, setExito ] = useState(false);

	const params = useParams();

	const token = params.token;

	const handleChange = e => {
		setInput({
			[e.target.name] : e.target.value
		})
	}

	const handleSubmit = async e => {
		e.preventDefault();
		
		if(!password) {
			return setError(t('Añade una nueva contraseña para recuperar tu cuenta'));
		}

		const result = await newPasswordApi(token, password);

		if(result.msg) {
			setError(result.msg);
		} else {
			setError(false);
			setInput({
				password: ''
			})
			setExito(true);
		} 
	}


	return ( 
		<div className="d-flex justify-content-center align-items-center fullpage">
			<div className="d-flex flex-column align-items-center">
				<img src={LogoForm} alt="Logo" className="logo-login mb-4 px-3" />
				<div className="card w-100">
					<div className="card-body">
						<form onSubmit={handleSubmit}>
							{error ? <div className="alert alert-danger text-center">{error}</div> : null}
							{exito ? <div className="alert alert-success text-center">{t('Contraseña cambiada correctamente')}.<br /> {t('Ya puedes')} <Link to="/login">{t('iniciar sesión')}</Link> {t('con esta nueva contraseña')}.</div> : null}
							<p className="text-muted"><span>{t('Guarda tu nueva contraseña para iniciar sesión')}.</span></p>
							<div className="mb-3">
								<input 
									type="password" 
									className="form-control" 
									name="password"
									onChange={handleChange}
									value={password}
									placeholder={t('Tu nueva contraseña')}
								/>
							</div>
							<ButtonSubmit text={t('Cambiar contraseña')} />
						</form>
					</div>
				</div>
			</div>
		</div>
	 );
}
 
export default NewPasswordPage;