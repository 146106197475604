import '../css/navbar.css';
import { NavLink, Link, Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import LogoNavbar from '../assets/img/logo-nav.svg';
import { PersonFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';


const Navbar = () => {

	const {t} = useTranslation();

	const { user, isLoading} = useAuth();


	if(!user && isLoading) {
		return 'Cargando...';
	}

	if(!user && !isLoading) {
		return <Navigate to="/login" />
	}

	return ( 
		<nav className="navbar navbar-expand-lg bg-white">
			<div className="container py-2">
				<div className="d-flex justify-content-between align-items-center w-100">
					<div className="d-flex">
						<Link to="/">
							<img src={LogoNavbar} alt="Logo" />
						</Link>
						<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
							<span className="navbar-toggler-icon"></span>
						</button>
						<div className="collapse navbar-collapse" id="navbarSupportedContent">
							<ul className="navbar-nav me-auto mb-2 mb-lg-0">
								<li className="nav-item">
							 		<NavLink className="nav-link" activeclassname="active" to="/" title={t('Facturas')}>{t('Facturas')}</NavLink>
								</li>
								<li className="nav-item">
									<NavLink className="nav-link" aria-current="page" to="/offers" title={t('Ofertas')}>{t('Ofertas')}</NavLink>
								</li>
								{/* <li className="nav-item">
									<a className="nav-link" aria-current="page" href="#" title="Documentación">Documentación</a>
								</li> */}
								<li className="nav-item">
									<NavLink className="nav-link" aria-current="page" to="/help" title={t('Ayuda')}>{t('Ayuda')}</NavLink>
								</li>
							</ul>
						</div>
					</div>
					<div className="d-flex align-items-center">
						{user.role === 'admin' ? (
							    <div className="collapse navbar-collapse" id="navbarNavDropdown">
									<ul className="navbar-nav">
										<li className="nav-item dropdown">
											<span className="nav-link dropdown-toggle pb-0" role="button" data-bs-toggle="dropdown" aria-expanded="false">
											Admin
											</span>
											<ul className="dropdown-menu">
												<li>
													<NavLink className="dropdown-item" to="/admin/suppliers" title="Proveedores">Proveedores</NavLink>
												</li>
												<li>
													<NavLink className="dropdown-item" to="/admin/invoices" title="Facturas">Facturas</NavLink>
												</li>
												<li>
													<NavLink className="dropdown-item" to="/admin/invoices-out" title="Fact. fuera">Facturas fuera</NavLink>
												</li>
												<li>
													<NavLink className="dropdown-item" to="/admin/reports" title="Descargas">Descargas</NavLink>
												</li>
												<li>
													<NavLink className="dropdown-item" to="/admin/offers" title="Ofertas">Ofertas</NavLink>
												</li>
												<li>
													<NavLink className="dropdown-item" to="/admin/config" title="Configurar">Configurar</NavLink>
												</li>
											</ul>
										</li>
									</ul>
							  	</div>
						) : null }
						<Link to="/profile" className="profile-icon"><PersonFill /></Link>
					</div>
				</div>
			</div>
		</nav>
	 );
}
 
export default Navbar;