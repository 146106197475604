import { useState } from 'react'
import moment from 'moment';
import { Check, X } from 'react-bootstrap-icons';
import { updateOffer } from "../../api/offer";

const badgeStatusStyle = (status) => {
	if(status === 'pending') {
		return 'text-bg-warning'
	} else if (status === 'approved') {
		return 'text-bg-success'
	} else {
		return 'text-bg-danger-red'
	}
}

const badgeStatusName = (status) => {
	if(status === 'pending') {
		return 'Pendiente'
	} else if (status === 'approved') {
		return 'Aprobada'
	} else {
		return 'Rechazada'
	}
}

const OfferRow = ({offer, token}) => {

	const { _id, supplier, url, initialDate, finalDate, price, status } = offer;

	const [ newDataOffer, setNewDataOffer ] = useState({
		_id: _id,
		status: '',
		supplier: supplier
	});

	const handleUpdate = async status => {

		const updatedDataOffer = {
			...newDataOffer,
			status: status
		};

		setNewDataOffer(updatedDataOffer)

		const result = await updateOffer(updatedDataOffer, token);
		
		if (result.ok) {
			setTimeout(function() { 
				window.location.reload();
			}, 400);
		} 

	}

	return ( 
		<tr className="my-2 bg-white">
			<td className="bold text-nowrap">{url}</td>
			<td className="text-nowrap">{moment(initialDate).format("DD/MM/YYYY")}</td>
			<td className="text-nowrap">{moment(finalDate).format("DD/MM/YYYY")}</td>
			<td className="text-nowrap text-center">{price}€</td>
			<td className="text-nowrap"><span className={`badge ${badgeStatusStyle(status)}`}>{badgeStatusName(status)}</span></td>
			<td>
				{status === 'pending'? <div><button className="btn btn-success mx-2 p-1" onClick={() => handleUpdate('approved')}><Check className="approved-btn" /></button><button className="btn btn-danger p-1" onClick={() => handleUpdate('denied')}><X className="approved-btn" /></button></div> : null }
			</td>
		</tr>
	 );
}
 
export default OfferRow;