import ButtonSubmit from '../ButtonSubmit';
import { Link } from 'react-router-dom';


const FormInvoice = ({handleSubmit, t, invoice, enterprise, handleChange, total, handleFile, exito, showButton}) => {

	const { date, expirationDate, refSupplier, description, price } = invoice;

	return (
		<form onSubmit={handleSubmit}>
		<p className="text-muted"><span>{t('Rellena los siguientes datos para dar de alta una nueva factura')}.</span></p>
		<div className="mb-3">
			<div className="form-text">{t('Fecha de emisión')}:</div>
			<input 
				type="date" 
				className="form-control" 
				name="date" 
				onChange={handleChange}
				value={date}
				placeholder={t('Fecha de emisión')}
			/>
		</div>
		<div className="mb-3">
			<div className="form-text">{t('Fecha de vencimiento')}:</div>
			<input 
				type="date" 
				className="form-control" 
				name="expirationDate" 
				onChange={handleChange}
				value={expirationDate}
				placeholder={t('Fecha de vencimiento')} 
			/>
		</div>
		<div className="mb-3">
			<div className="form-text">{t('Número de factura')}:</div>
			<input 
				type="text" 
				className="form-control" 
				name="refSupplier" 
				onChange={handleChange}
				value={refSupplier}
				placeholder="A-2024-1" 
			/>
		</div>
		<div className="mb-3">
			<div className="form-text">{t('Concepto (nº de pedido + nombre del medio)')}:</div>
			<input 
				type="text" 
				className="form-control" 
				 name="description" 
				onChange={handleChange}
				value={description}
				placeholder="40359 - elperiodico.com" 
			/>
		</div>
		<div className="mb-3">
			<div className="form-text">{t('Precio neto')}:</div>
			<input 
				type="number" 
				className="form-control" 
				name="price" 
				onChange={handleChange}
				value={price}
				onWheel={(e) => e.target.blur()}
			/>
		</div>
		<div className="mb-3">
			<div className="form-text">{t('% de IVA')}:</div>
			<input 
				type="number" 
				className="form-control" 
				name="iva" 
				value={enterprise.iva}
				placeholder={t('IVA')}
				disabled
			/>
		</div>
		<div className="mb-3">
			<div className="form-text">{t('% de IRPF')}:</div>
			<input 
				type="number" 
				className="form-control" 
				name="irpf" 
				disabled
				value={enterprise.irpf}
				placeholder={t('IRPF')}
			/>
		</div>
		<div className="mb-3">
			<div className="form-text">{t('Total de la factura')}:</div>
			<input 
				type="number" 
				className="form-control" 
				name="total" 
				disabled
				placeholder={`${total}€`}
			/>
		</div>
		<div className="mb-3">
		<div className="form-text mb-2">{t('Forma de pago: (puedes cambiar la forma de pago desde')} <Link to="/profile">{t('tu perfil')}</Link>):</div>
			<select className="form-select" name="payment" value={enterprise.payment} disabled>
				<option value="">{t('Forma de pago')}</option>
				<option value="banco">{t('Transferencia bancaria')}</option>
				<option value="paypal">Paypal</option>
				<option value="saldo">{t('Saldo de Prensalink')}</option>
			</select>
		</div>
		{ enterprise.payment === 'banco' ? (
		<>
			<div className="mb-3">
				<div className="form-text">{t('Beneficiario')}:</div>
				<input 
					type="text" 
					className="form-control" 
					name="accountName" 
					disabled
					value={enterprise.accountName}
					placeholder={t('Nombre del beneficiario de la transferencia')}
				/>
			</div> 
			<div className="mb-3">
				<div className="form-text">{t('Número de cuenta (IBAN)')}:</div>
				<input 
					type="text" 
					className="form-control" 
					name="iban" 
					disabled
					value={enterprise.iban}
					placeholder={t('Número de cuenta (IBAN)')} 
				/>
			</div> 
		</>
		): null}
		{ enterprise.payment === 'paypal' ? (
			<div className="mb-3">
				<div className="form-text">{t('Email de Paypal')}:</div>
				<input 
					type="text" 
					className="form-control" 
					name="paypalEmail" 
					disabled
					value={enterprise.paypalEmail}
					placeholder={t('Email de Paypal')} 
				/>
			</div> 
		): null}
		{ enterprise.payment === 'saldo' ? (
			<div className="mb-3">
				<div className="form-text">{t('Email de Prensalink')}:</div>
				<input 
					type="text" 
					className="form-control" 
					name="prensalinkEmail" 
					disabled
					value={enterprise.prensalinkEmail}
					placeholder={t('Email de Prensalink')} 
				/>
			</div> 
		): null}
		<div className="mb-3">
			<div className="form-text">{t('Adjuntar archivo (solo pdf permitido)')}:</div>
			<input 
				className="form-control" 
				type="file"
				name="file"
				onChange={handleFile}
				accept="application/pdf"
			/>
		
		<div className="form-text mt-4">{t('Comprueba que el total coincide con tu factura antes de enviarla')}.</div>

		</div>
		{exito ? <div className="mt-3 alert alert-success text-center registro-exitoso" role="alert"><p className="mb-0">{t('Factura enviada correctamente')}.</p></div> : null }
		{showButton && <ButtonSubmit text={t('Enviar factura')} /> }
	</form>
	)
}

export default FormInvoice
