import React, { useState } from 'react';
import LogoForm from '../assets/img/logo-form.png';
import '../css/login.css';
import ButtonSubmit from '../components/ButtonSubmit';
import { Link, Navigate } from "react-router-dom";
import { loginApi } from '../api/user';
import { REFRESH_TOKEN, ACCESS_TOKEN } from '../utils/constants';
import { getAccessToken } from '../api/auth';
import { useTranslation } from 'react-i18next';

const LoginPage = () => {

	const {t} = useTranslation();

	const [ user, setUser ] = useState({
		email: '',
		password: ''
	});

	const [ error, setError ] = useState('');

	const { email, password } = user;

	const handleChange = e => {
		setUser({
			...user,
			[e.target.name] : e.target.value
		})
	};

	const handleSubmit = async e => {

		e.preventDefault();

		if(email.trim() === '' || password.trim() === '') {
			setError(t('Todos los campos son obligatorios'));
			return;
		}

		const result = await loginApi(user);

		if(result.errors) {	
			setError(result.msg);
			setUser({
				email: '',
				password: ''
			});
		}else {

			const { accessToken, refreshToken } = result;
			localStorage.setItem(ACCESS_TOKEN, accessToken);
			localStorage.setItem(REFRESH_TOKEN, refreshToken);
			window.location.href = "/";

			setUser({
				email: '',
				password: ''
			});

		}
	}

	if (getAccessToken()) {
		return <Navigate to="/" />
	}


	return ( 
			<div className="d-flex justify-content-center align-items-center fullpage">
				<div className="d-flex flex-column align-items-center">
					<img src={LogoForm} alt="Logo" className="logo-login mb-4 px-3" />
					<div className="card w-100">
						<div className="card-body">
						{ error ? <div className="alert alert-danger text-center" role="alert">{error}</div> : null }
							<form onSubmit={handleSubmit}>
								<p className="text-muted"><span>{t('Iniciar sesión en tu cuenta')}</span></p>
								<div className="mb-3">
									<input 
										type="email" 
										className="form-control" 
										name="email" 
										value={email}
										onChange={handleChange}
										placeholder="Email" 
									/>
								</div>
								<div className="mb-3">
									<input 
										type="password" 
										className="form-control" 
										name="password" 
										value={password}
										onChange={handleChange}
										placeholder={t('Contraseña')}
									/>
								</div>
								<ButtonSubmit text={t('Entrar')} />
							</form>
						</div>
					</div>
					<div className="mt-3">
						<Link className="col-7 mr-link" to="/forgot-password">
							<span>{t('¿Olvidaste tu contraseña?')}</span>
						</Link>
						<a className="col-5 text-right" href="/signup">
							<span>{t('Nuevo usuario')}</span>
						</a>
					</div>
				</div>
			</div>
	 );
}
 
export default LoginPage;