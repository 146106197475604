import { useState } from "react"
import useAuth from '../hooks/useAuth';
import Navbar from '../components/Navbar';
import Breadcrumbs from '../components/Breadcrumbs';
import { Navigate } from 'react-router-dom';
import ButtonSubmit from "../components/ButtonSubmit";
import { offerApi } from "../api/user";
import { newOffer } from "../api/offer";
import { getAccessToken } from '../api/auth';
import { useTranslation } from 'react-i18next';

const Offers = () => {

	const {t} = useTranslation();

	const { user, isLoading } = useAuth();

	const [ form, setForm ] = useState({
		initialDate: '',
		finalDate: '',
		url: '',
		price: ''
	});

	const { initialDate, finalDate, url, price } = form;

	const token = getAccessToken();

	const [ error, setError ] = useState('');
	const [ exito, setExito ] = useState(false);

	const handleChange = e => {
		setForm({
			...form,
			[e.target.name] : e.target.value
		})
	};

	const handleSubmit = async e => {

		e.preventDefault();

		if(initialDate === '' || finalDate === '' || url === '' || price === '') {
			setError(t('Todos los campos son obligatorios'));
			return;
		}

		const result = await newOffer(form, token);

		if(!result.ok) {
			setError(result.message);
			setExito(false);
			
		} else {
			setError('');
			setForm({
				initialDate: '',
				finalDate: '',
				price: '',
				url: ''
			});
			setExito(true);
			await offerApi(form, token);
		}
	}

	if (!user && isLoading) return 'Cargando...';

	if(!user && !isLoading) {
		return (
			<Navigate to="/login" />
		)
	}

	return ( 
		<>
			<Navbar />
			<main>
			<div className="container">
				<Breadcrumbs currentPage={t('Ofertas')} />
				<div className="row">
					<div className="p-0 col-12 title">
						<h3 className="p-1">{t('Crear una nueva oferta')}</h3>
					</div>
				</div>
				<div className="row">
					<div className="card">
						<div className="card-body">
							<p>{t('Si quieres aumentar las ventas de tu medio, desde esta página puedes enviarnos un precio de oferta. La oferta debe ser mínimo un 30% por debajo respecto al precio original.')}.</p>
							<p>{t('Los medios en oferta aumentan sus ventas hasta un 300%')}:</p>
							<ul>
								<li>{t('Aparecen marcados como Rebajados')}</li>
								<li>{t('Se destacan al principio de la tabla')}</li>
								<li>{t('Se envía la oferta en nuestra mailing semanal con una base de datos de más de 20.000 usuarios registrados')}</li>
							</ul>
							<p>{t('Ten en cuenta que podrán llegarte artículos patrocinados a precio de esta promoción unos días después de la fecha indicada por demora en la redacción y aprobación del texto por parte del cliente. Puedes solicitar un reporte de todos los artículos vendidos el día en el que finaliza la promoción.')}</p>
							{ error ? <div className="alert alert-danger text-center">{error}</div> : null }
							{ exito ? <div className="alert alert-success text-center">{t('Oferta enviada correctamente')}.</div> : null}
							<form onSubmit={handleSubmit}>
								<div className="mb-3">
									<span className="text-muted">{t('Fecha de inicio de la oferta')}:</span>
									<input 
										type="date" 
										className="form-control" 
										name="initialDate"
										value={initialDate}
										onChange={handleChange} 
									/>
								</div>
								<div className="mb-3">
									<span className="text-muted">{t('Fecha de finalización de la oferta')}:</span>
									<input 
										type="date" 
										className="form-control" 
										name="finalDate"
										value={finalDate}
										onChange={handleChange} 
									/>
								</div>
								<div className="mb-3">
									<span className="text-muted">{t('URL del medio en oferta')}:</span>
									<input 
										type="url" 
										className="form-control" 
										name="url"
										value={url}
										onChange={handleChange} 
										placeholder={t('https://tumedio.com')} 
									/>
								</div>
								<div className="mb-3">
									<span className="text-muted">{t('Precio de oferta')}:</span>
									<input 
										type="text" 
										className="form-control" 
										name="price"
										value={price}
										onChange={handleChange} 
										placeholder="€" 
									/>
								</div>
								<div className="d-flex w-25">
									<ButtonSubmit text={t('Enviar oferta')} />
								</div>
							</form>
						</div>
					</div>
				</div>
				</div>
			</main>
		</>
	 );
}
 
export default Offers;