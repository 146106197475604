import { basePath } from './config';

export async function newEnterprise(data, token) {
  const url = `${basePath}/api/enterprises/new`;
  const params = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
	  "Content-Type": "application/json",
	  "Authorization": token
    }
  };

  return await fetch(url, params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      if (result.enterprise) {
        return { ok: true, message: "Perfil de facturación creado correctamente" };
      }
      return { ok: false, message: result.msg };
    })
    .catch(err => {
      return { ok: false, message: err.msg };
    });
};

export async function newEnterpriseAdmin(data, token) {
	const url = `${basePath}/api/enterprises/new-admin`;
	const params = {
	  method: "POST",
	  body: JSON.stringify(data),
	  headers: {
		"Content-Type": "application/json",
		"Authorization": token
	  }
	};
  
	return await fetch(url, params)
	  .then(response => {
		return response.json();
	  })
	  .then(result => {
		if (result.enterprise) {
		  return { ok: true, message: "Perfil de facturación creado correctamente" };
		}
		return { ok: false, message: result.msg };
	  })
	  .catch(err => {
		return { ok: false, message: err.msg };
	  });
  };

export async function getUserEnterprise(token) {
	const url = `${basePath}/api/enterprises/user`;
	const params = {
	  method: "GET",
	  body: JSON.stringify(),
	  headers: {
		"Content-Type": "application/json",
		"Authorization": token
	  }
	};
  
	return await fetch(url, params)
		.then(response => {
			return response.json();
		})
		.then(result => {
			return result;
		})
		.catch(err => {
			return err.message;
		})
  };

  export async function getAllEnterprises(token) {
	const url = `${basePath}/api/enterprises/`;
	const params = {
	  method: "GET",
	  body: JSON.stringify(),
	  headers: {
		"Content-Type": "application/json",
		"Authorization": token
	  }
	};
  
	return await fetch(url, params)
		.then(response => {
			return response.json();
		})
		.then(result => {
			return result;
		})
		.catch(err => {
			return err.message;
		})
  };

  export async function updateEnterprise(data, token) {
	const url = `${basePath}/api/enterprises/edit`;
	const params = {
	  method: "PUT",
	  body: JSON.stringify(data),
	  headers: {
		"Content-Type": "application/json",
		"Authorization": token
	  }
	};
  
	return await fetch(url, params)
	  .then(response => {
		return response.json();
	  })
	  .then(result => {
		if (result.enterprise) {
		  return { ok: true, message: "Perfil de facturación editado correctamente" };
		}
		return { ok: false, message: result.msg };
	  })
	  .catch(err => {
		return { ok: false, message: err.msg };
	  });
  };
  export async function removeOffer(id, token) {

	const data = { _id: id}
	const url = `${basePath}/api/enterprises/remove-offer`;
	const params = {
	  method: "PUT",
	  body: JSON.stringify(data),
	  headers: {
		"Content-Type": "application/json",
		"Authorization": token
	  }
	};
  
	return await fetch(url, params)
	  .then(response => {
		return response.json();
	  })
	  .then(result => {
		if (result.enterprise) {
		  return { ok: true, message: "Perfil de facturación editado correctamente" };
		}
		return { ok: false, message: result.msg };
	  })
	  .catch(err => {
		return { ok: false, message: err.msg };
	  });
  };

  export async function createOfferAlert(id, token) {

	const data = { _id: id}
	const url = `${basePath}/api/enterprises/create-offer-alert`;
	const params = {
	  method: "PUT",
	  body: JSON.stringify(data),
	  headers: {
		"Content-Type": "application/json",
		"Authorization": token
	  }
	};
  
	return await fetch(url, params)
	  .then(response => {
		return response.json();
	  })
	  .then(result => {
		if (result.enterprise) {
		  return { ok: true, message: "Perfil de facturación editado correctamente" };
		}
		return { ok: false, message: result.msg };
	  })
	  .catch(err => {
		return { ok: false, message: err.msg };
	  });
  };
  
  export async function isMultipleFn(_id, token) {
	const data = {"_id": _id};
	const url = `${basePath}/api/enterprises/is-multiple`;
	const params = {
	  method: "POST",
	  body: JSON.stringify(data),
	  headers: {
		"Content-Type": "application/json",
		"Authorization": token
	  }
	};
  
	return await fetch(url, params)
	  .then(response => {
		return response.json();
	  })
	  .then(result => {
		if (result.enterprise) {
		  return { ok: true, message: "Perfil de facturación editado correctamente" };
		}
		return { ok: false, message: result.msg };
	  })
	  .catch(err => {
		return { ok: false, message: err.msg };
	  });
  };

  export async function getUserEnterpriseById(id,token) {
	const url = `${basePath}/api/enterprises/${id}`;
	const params = {
	  method: "GET",
	  body: JSON.stringify(),
	  headers: {
		"Content-Type": "application/json",
		"Authorization": token
	  }
	};
  
	return await fetch(url, params)
		.then(response => {
			return response.json();
		})
		.then(result => {
			return result;
		})
		.catch(err => {
			return err.message;
		})
  };

  export async function updateEnterprisePayment(data, token) {
	const url = `${basePath}/api/enterprises/edit-payment`;
	const params = {
	  method: "PUT",
	  body: JSON.stringify(data),
	  headers: {
		"Content-Type": "application/json",
		"Authorization": token
	  }
	};
  
	return await fetch(url, params)
	  .then(response => {
		return response.json();
	  })
	  .then(result => {
		if (result.enterprise) {
		  return { ok: true, message: "Perfil de facturación editado correctamente" };
		}
		return { ok: false, message: result.msg };
	  })
	  .catch(err => {
		return { ok: false, message: err.msg };
	  });
  };

  export async function verifiedEnterprise(id, token) {

	const data = {
		_id: id
	}
	const url = `${basePath}/api/enterprises/verified`;
	const params = {
	  method: "POST",
	  body: JSON.stringify(data),
	  headers: {
		"Content-Type": "application/json",
		"Authorization": token
	  }
	};
  
	return await fetch(url, params)
	  .then(response => {
		return response.json();
	  })
	  .then(result => {
		if (result.enterprise) {
		  return { ok: true, message: "Perfil de facturación verificado correctamente" };
		}
		return { ok: false, message: result.msg };
	  })
	  .catch(err => {
		return { ok: false, message: err.msg };
	  });
  };