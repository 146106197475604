import React, { useState, useEffect } from 'react';
import useAuth from '../../hooks/useAuth';
import { Navigate, Link } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import Breadcrumbs from '../../components/Breadcrumbs';
import TableSuppliers from '../../components/Admin/TableSuppliers';
import { getAllEnterprises } from '../../api/enterprise';
import { getAccessToken } from '../../api/auth';
import { Search } from 'react-bootstrap-icons';

const SuppliersPage = () => {

	const { user, isLoading } = useAuth();

	const [ suppliers, setSuppliers ] = useState([]);

	const token = getAccessToken();

	const [ tablaSuppliers, setTablaSuppliers ] = useState([]);
	const [ busqueda, setBusqueda ] = useState("");


	useEffect(() => {

		async function fetchDatos() {
			await getAllEnterprises(token).then(response => {
				setSuppliers(response.enterprises);
				setTablaSuppliers(response.enterprises);
			});
		}

		fetchDatos();

	}, [token]);

	const handleChange = e => {
		setBusqueda(e.target.value);
		filtrar(e.target.value);
	}

	const filtrar=(terminoBusqueda)=>{
		var resultadosBusqueda=tablaSuppliers.filter((elemento)=>{
		  if(elemento.name.toString().toLowerCase().includes(terminoBusqueda.toLowerCase()) || elemento.dni.toString().toLowerCase().includes(terminoBusqueda.toLowerCase()) || elemento.companyName.toString().toLowerCase().includes(terminoBusqueda.toLowerCase()) || elemento.email.toString().toLowerCase().includes(terminoBusqueda.toLowerCase()) ){
			return elemento;
		  }
		});
		setSuppliers(resultadosBusqueda);
	  }

	if (!user && isLoading) return 'Cargando...';

	if(!user && !isLoading) {
		return (
			<Navigate to="/login" />
		)
	}

	if (user && user.role !== 'admin') {
		return (
			<Navigate to="/" />
		)
	}

	return ( 
		<>
			<Navbar />
			<main>
				<div className="container pb-5">
					<Breadcrumbs currentPage="Proveedores" />
					<div className="row mb-4 pb-3">
						<div className="p-0 col-12 title d-flex justify-content-between align-items-center">
							<h1 className="p-1">Proveedores</h1>
							<Link to="/admin/suppliers/new" className="btn btn-primary">Nuevo proveedor</Link>
						</div>
					</div>
					<form className="w-100">
						<div className="d-flex my-3 w-100 align-items-center search-admin">
							<span className="lupa"><Search/></span>
							<input
								className="form-control me-2 w-100"
								value={busqueda}
								placeholder="Buscar por nombre, empresa, email o dni/cif."
								onChange={handleChange}
							/>
						</div>
					</form>
					<TableSuppliers suppliers={suppliers} token={token} />
				</div>
			</main>
		</>
	 );
}
 
export default SuppliersPage;