import Navbar from "../components/Navbar"
import useAuth from '../hooks/useAuth';
import { Navigate } from 'react-router-dom';
import Breadcrumbs from "../components/Breadcrumbs";
import ButtonSubmit from "../components/ButtonSubmit";
import { useState } from "react";
import { helpApi } from "../api/user";
import { getAccessToken } from '../api/auth';
import { useTranslation } from 'react-i18next';

const HelPage = () => {

	const {t} = useTranslation();

	const { user, isLoading } = useAuth();

	const [ form, setForm ] = useState({
		topic: '',
		description: ''
	});

	const { topic, description } = form;

	const token = getAccessToken();

	const [ error, setError ] = useState('');
	const [ exito, setExito ] = useState(false);

	const handleChange = e => {
		setForm({
			...form,
			[e.target.name] : e.target.value
		})
	};

	const handleSubmit = async e => {

		e.preventDefault();

		if(topic === '' || description === '') {
			setError(t('Todos los campos son obligatorios'));
			return;
		}

		const result = await helpApi(form, token);

		if(result.errors) {	
			setError(result.msg);
			setExito(false);
		}else {

			setExito(true);

			setForm({
				topic: '',
				description: ''
			});

		}
	}


	if (!user && isLoading) return 'Cargando...';

	if(!user && !isLoading) {
		return (
			<Navigate to="/login" />
		)
	}

	return ( 
		<>
			<Navbar />
			<main>
			<div className="container">
				<Breadcrumbs currentPage={t('Ayuda')} />
				<div className="row">
					<div className="p-0 col-12 title">
						<h3 className="p-1">{t('¿Necesitas ayuda?')}</h3>
					</div>
				</div>
				<div className="row">
					<div className="card">
						<div className="card-body">
							<p className="text-muted">{t('Cuéntanos en que podemos ayudarte y te contestaremos lo antes posible a tu email')}</p>
							{ error ? <div className="alert alert-danger text-center">{error}</div> : null }
							{ exito ? <div className="alert alert-success text-center">{t('Mensaje enviado correctamente, te responderemos lo antes posible')}.</div> : null}
							<form onSubmit={handleSubmit}>
								<div className="mb-3">
									<input 
										type="text" 
										className="form-control" 
										name="topic"
										value={topic}
										onChange={handleChange} 
										placeholder={t('Asunto del mensaje')} 
									/>
								</div>
								<div className="mb-3">
									<textarea 
										className="form-group form-control" 
										rows="10" 
										name="description" 
										value={description}
										onChange={handleChange}
										placeholder={t('Descripción del problema')}>
									</textarea>
								</div>
								<div className="d-flex w-25">
									<ButtonSubmit text={t('Enviar')} />
								</div>
							</form>
						</div>
					</div>
				</div>
				</div>
			</main>
		</>
	 );
}
 
export default HelPage;