import React, { useState } from 'react';
import LogoForm from '../assets/img/logo-form.png';
import '../css/login.css';
import ButtonSubmit from '../components/ButtonSubmit';
import { Link, Navigate } from 'react-router-dom';
import { newEnterprise } from '../api/enterprise';
import useAuth from '../hooks/useAuth';
import { getAccessToken, refreshAccessToken, getRefreshToken } from '../api/auth';
import { useTranslation } from 'react-i18next';

const NewProfilePage = () => {

	const {t} = useTranslation();

	const { user, isLoading } = useAuth();

	const token = getAccessToken();
	const refreshToken = getRefreshToken();

	const [ profile, setProfile ] = useState({
		name: '',
		surname: '',
		dni: '',
		companyName: '',
		address: '',
		cp: '',
		city: '',
		country: '',
		phone: '',
		payment: '',
		accountName: '',
		iban: '',
		paypalEmail: '',
		prensalinkEmail: '',
		iva: '',
		irpf: ''
	});

	const [ error, setError ] = useState({
		msg: '',
		status: false
	});

	const [ exito, setExito ] = useState(false);


	const { name, surname, dni, companyName, prensalinkEmail, accountName, paypalEmail, iban, iva, irpf, address, cp, city, country, phone, payment } = profile;

	const handleChange = e => {
		setProfile({
			...profile,
			[e.target.name] : e.target.value
		})
	};

	const handleSubmit = async e => {
		e.preventDefault();

		if(payment === 'saldo') {
			if(name.trim() === '' || surname.trim() === '' || dni.trim() === '' || address.trim() === '' || cp.trim() === '' || city.trim() === '' || country.trim() === '' || phone.trim() === '' || payment === '') {
				setError({
					msg: t('Todos los campos son obligatorios.'),
					status: true
				});
				setExito(false);
				return;
			}
		} else {
			if(name.trim() === '' || surname.trim() === '' || dni.trim() === '' || address.trim() === '' || cp.trim() === '' || city.trim() === '' || country.trim() === '' || phone.trim() === '' || payment === '' || irpf === '' || iva === '') {
				setError({
					msg: t('Todos los campos son obligatorios.'),
					status: true
				});
				setExito(false);
				return;
			}
		}

		

		const result = await newEnterprise(profile, token);

		if(!result.ok) {
			setError({
				msg: result.message,
				status: true
			});
			setExito(false);
			
		} else {
			setError({
				msg: "",
				status: false
			});
			setProfile({
				name: '',
				surname: '',
				dni: '',
				companyName: '',
				address: '',
				cp: '',
				city: '',
				country: '',
				phone: '',
				payment: '',
				accountName: '',
				iban: '',
				paypalEmail: '',
				prensalinkEmail: '',
				iva: '',
				irpf: ''
			});
			setExito(true);
			await refreshAccessToken(refreshToken);
			setTimeout(function() { 
				window.location.reload('/');
			}, 500);
		}

	}

	if (!user && isLoading) return 'Cargando...';

	if(!user && !isLoading) {
		return (
			<Navigate to="/login" />
		)
	}


	return ( 
		<div className="d-flex justify-content-center align-items-center fullpage">
			<div className="d-flex flex-column align-items-center">
				<img src={LogoForm} alt="Logo" className="logo-login mb-4 px-3" />
				<div className="card w-100">
					<div className="card-body">
						{ error.status ? <div className="alert alert-danger text-center" role="alert">{error.msg}</div> : null }
						<form onSubmit={handleSubmit}>
							<p className="text-muted"><span>{t('Rellena los siguientes datos para crear tu perfil de facturación y poder adjuntar las facturas')}.</span></p>
							<div className="mb-3">
								<input 
									type="text" 
									className="form-control" 
									name="name" 
									onChange={handleChange}
									value={name}
									placeholder={t('Nombre')} 
								/>
							</div>
							<div className="mb-3">
								<input 
									type="text" 
									className="form-control" 
									name="surname" 
									onChange={handleChange}
									value={surname}
									placeholder={t('Apellidos')} 
								/>
							</div>
							<div className="mb-3">
								<input 
									type="text" 
									className="form-control" 
									name="companyName" 
									onChange={handleChange}
									value={companyName}
									placeholder={t('Nombre de la empresa (opcional)')} 
								/>
							</div>
							<div className="mb-3">
								<input 
									type="text" 
									className="form-control" 
									name="dni" 
									onChange={handleChange}
									value={dni}
									placeholder={t('DNI / CIF(empresas)')} 
								/>
							</div>
							<div className="mb-3">
								<select className="form-select" name="payment" value={payment} onChange={handleChange}>
									<option value="">{t('Forma de pago')}</option>
									<option value="banco">{t('Transferencia bancaria')}</option>
									<option value="paypal">Paypal</option>
									<option value="saldo">{t('Saldo de Prensalink')}</option>
								</select>
							</div>
							{ payment === 'banco' ? (
							<>
								<div className="mb-3">
									<input 
										type="text" 
										className="form-control" 
										name="accountName" 
										onChange={handleChange}
										value={accountName}
										placeholder={t('Nombre del beneficiario de la transferencia')} 
									/>
								</div> 
								<div className="mb-3">
									<input 
										type="text" 
										className="form-control" 
										name="iban" 
										onChange={handleChange}
										value={iban}
										placeholder={t('Número de cuenta (IBAN)')}  
									/>
								</div> 
							</>
							): null}
							{ payment === 'paypal' ? (
								<div className="mb-3">
									<input 
										type="text" 
										className="form-control" 
										name="paypalEmail" 
										onChange={handleChange}
										value={paypalEmail}
										placeholder={t('Email de Paypal')}  
									/>
								</div> 
							): null}
							{ payment === 'saldo' ? (
								<div className="mb-3">
						 			<input 
										type="text" 
										className="form-control" 
										name="prensalinkEmail" 
										onChange={handleChange}
										value={prensalinkEmail}
										placeholder={t('Email de Prensalink')} 
									/>
								</div> 
							): null}
							{ payment !== 'saldo' ? (
								<>
								<div className="mb-3">
									<input 
										type="number" 
										className="form-control" 
										name="irpf" 
										onChange={handleChange}
										value={irpf}
										placeholder={t('% de IRPF')}
										onWheel={(e) => e.target.blur()}
									/>
								</div>
								<div className="mb-3">
									<input 
										type="number" 
										className="form-control" 
										name="iva" 
										onChange={handleChange}
										value={iva}
										placeholder={t('% de IVA')}
										onWheel={(e) => e.target.blur()}
									/>
								</div>
								</>
							) : null}
							<div className="mb-3">
								<input 
									type="text" 
									className="form-control" 
									name="address" 
									onChange={handleChange}
									value={address}
									placeholder={t('Dirección de facturación')}
								/>
							</div>
							<div className="mb-3">
								<input 
									type="text" 
									className="form-control" 
									name="cp" 
									onChange={handleChange}
									value={cp}
									placeholder={t('Código Postal')} 
								/>
							</div>
							<div className="mb-3">
								<input 
									type="text" 
									className="form-control" 
									name="city" 
									onChange={handleChange}
									value={city}
									placeholder={t('Localidad')} 
								/>
							</div>
							<div className="mb-3">
								<input 
									type="text" 
									className="form-control" 
									name="country" 
									onChange={handleChange}
									value={country}
									placeholder={t('País')} 
								/>
							</div>
							<div className="mb-3">
								<input 
									type="text" 
									className="form-control" 
									name="phone" 
									onChange={handleChange}
									value={phone}
									placeholder={t('Teléfono')} 
								/>
							</div>
							<ButtonSubmit text={t('Enviar')} />
						</form>
						{exito ? <div className="mt-3 alert alert-success text-center registro-exitoso" role="alert"><p className="mb-0">{t('Perfil de facturación creado correctamente')}.</p> <p className="mb-0">{t('Ya puedes')} <Link to="/new-invoice" className="link-form">{t('subir una factura')}</Link>.</p></div> : null }
					</div>
				</div>
			</div>
		</div>
	 );
}
 
export default NewProfilePage;