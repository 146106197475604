import useAuth from '../../hooks/useAuth';
import { Navigate } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import Breadcrumbs from '../../components/Breadcrumbs';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { getUserEnterpriseById } from '../../api/enterprise';
import { getAccessToken } from '../../api/auth';
import TableInvoicesSingleUser from '../../components/Admin/TableInvoicesSingleUser';


const InvoicesSupplierPage = () => {

	const { user, isLoading } = useAuth();

	const params = useParams();

	const supplierId = params.supplierId;


	const [enterprise, setEnterprise ] = useState([]);

	const token = getAccessToken();

	useEffect(() => {

		async function fetchDatos() {
			await getUserEnterpriseById(supplierId,token).then(response => {
				setEnterprise(response.enterprise);
			});
		}

		fetchDatos();

	}, [token]);

	if (!user && isLoading) return 'Cargando...';

	if(!user && !isLoading) {
		return (
			<Navigate to="/login" />
		)
	}

	if (user && user.role !== 'admin') {
		return (
			<Navigate to="/" />
		)
	}

	return ( 
		<>
			<Navbar />
			<main>
				<div className="container pb-5">
					<Breadcrumbs currentPage="Facturas" />
					<div className="row mb-4 pb-3">
						<div className="p-0 col-12 title d-flex justify-content-between align-items-center">
							<h1 className="p-1">Facturas de {enterprise.companyName ? enterprise.companyName : enterprise.name + ' ' + enterprise.surname}</h1>
						</div>
					</div>
					<TableInvoicesSingleUser supplierId={supplierId} />
				</div>
			</main>
		</>
	 );
}
 
export default InvoicesSupplierPage;