import React from 'react';
import useAuth from '../hooks/useAuth';
import { Navigate, Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Breadcrumbs from '../components/Breadcrumbs';
import TableInvoice from '../components/TableInvoice';
import { getAccessToken } from '../api/auth';
import NewProfilePage from './NewProfilePage';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const HomePage = () => {

	const {t} = useTranslation();

	const { user, isLoading } = useAuth();

	const token = getAccessToken();


	if (!user && isLoading) return 'Cargando...';

	if(!user && !isLoading) {
		return (
			<Navigate to="/login" />
		) 
	}

	if (user.invoiceProfile) {
		return (
			<div>
				<Navbar />
				<main>
					<div className="container">

					<Alert variant="danger" className="mt-4">
						<span>
							{t('Desde el 01/01/2024 las facturas subidas deben incluir la nueva dirección de facturación: Vía Emilio Ortuño, 28, Edificio Hidalgo, 12A, 03501 Benidorm (España)')}.
						</span>
					</Alert>
						<Breadcrumbs currentPage={t('Facturas')} />
						<div className="row mb-4 pb-3">
							<div className="p-0 col-12 title d-flex justify-content-between align-items-center">
								<h1 className="p-1">{t('Mis Facturas')}</h1>
								<Link to="/new-invoice" className="btn btn-primary">{t('Nueva factura')}</Link>
							</div>
						</div>
						<TableInvoice token={token} />
					</div>
				</main>
			</div>
		)
	} else {
		return (
			<NewProfilePage />
		)
	}
}
 
export default HomePage;