import Paypal from '../assets/img/paypal-icon.svg';
import Saldo from '../assets/img/logo-nav.svg';
import Bank from '../assets/img/bank.svg';
import moment from 'moment';
import { EyeFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

const RowInvoice = ({invoice}) => {

	const {t} = useTranslation();

	const { refSupplier, date, total, payment, paymentMode, ref } = invoice;

	const paymentModeFn = (payment) => {

		if(payment === 'banco') {
			return (
				<td>
					<img src={Bank} className="logo-money" alt="Transferencia" />
					<span>{t('Transferencia')}</span>
				</td>)
		} else if (payment === 'saldo') {
			return (
				<td>
					<img src={Saldo} className="logo-money" alt="Saldo" />
					<span>{t('Saldo')}</span>
				</td>)
		} else if (payment === 'paypal') {
			return (
				<td>
					<img src={Paypal} className="logo-money" alt="Paypal" />
					<span>Paypal</span>
				</td>)
		} else if (payment === 'giro') {
			return (
				<td className="text-nowrap"> 
					<img src={Bank} className="logo-money" alt="giro" />
					<span>{t('Giro bancario')}</span>
				</td>)
		} else {
			return ( 
				<td>
					<span>N/A</span>
				</td>)
		};
		
	}

	return ( 
		<tr className="bg-white my-2">
			<td className="bold">{refSupplier}</td>
			<td>{moment(date).format("DD/MM/YYYY")}</td>
			<td>
				{payment === 'pendiente' ? <span className="badge text-bg-danger">{t('Pendiente')}</span> : <span className="badge text-bg-success">{t('Pagado')}</span>}
			</td>
			{paymentModeFn(paymentMode)}
			<td>{total} €</td>
			<td className="text-right">
				{paymentMode === 'saldo' ? null : (
					<a className="btn btn-primary px-2 py-0" target="_blank" rel="noreferrer" href={`https://api-proveedores.prensalink.com/invoices/${ref}.pdf`}>
						<EyeFill />
					</a>
				)}
			</td>
		</tr> 
	 );
}
 
export default RowInvoice;