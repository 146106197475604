import React, { useState, useEffect, useRef } from 'react';
import useAuth from '../../hooks/useAuth';
import { Navigate } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import Breadcrumbs from '../../components/Breadcrumbs';
import TableInvoices from '../../components/Admin/TableInvoices';
import { getAccessToken } from '../../api/auth';
import { getAllInvoices } from '../../api/invoice';
import { Search } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

const InvoicesPage = () => {
    const { user, isLoading } = useAuth();
    const token = getAccessToken();
    const [invoices, setInvoices] = useState([]);
    const [busqueda, setBusqueda] = useState("");
    const debounceTimeout = useRef(null);

    useEffect(() => {
        fetchDatos();
    }, [token]);

    const fetchDatos = async (terminoBusqueda = "") => {
        await getAllInvoices(token, terminoBusqueda).then(response => {
            setInvoices(response.invoices);
        });
    };

    const handleChange = e => {
        setBusqueda(e.target.value);
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        debounceTimeout.current = setTimeout(() => {
            fetchDatos(e.target.value);
        }, 500);
	}
	
	const handleFormSubmit = (e) => {
		e.preventDefault();
	};

    if (!user && isLoading) return 'Cargando...';
    if (!user && !isLoading) {
        return (
            <Navigate to="/login" />
        );
    }
    if (user && user.role !== 'admin') {
        return (
            <Navigate to="/" />
        );
    }

    return (
        <>
            <Navbar />
            <main>
                <div className="container pb-5">
                    <Breadcrumbs currentPage="Facturas" />
                    <div className="row mb-4 pb-3">
                        <div className="p-0 col-12 title d-flex justify-content-between align-items-center">
                            <h1 className="p-1">Facturas</h1>
                            <Link to="/admin/invoices/new" className="btn btn-primary">Nueva factura</Link>
                        </div>
                    </div>
                    <form className="w-100" onSubmit={handleFormSubmit}>
                        <div className="d-flex my-3 w-100 align-items-center search-admin">
                            <span className="lupa"><Search /></span>
                            <input
                                className="form-control me-2 w-100"
                                value={busqueda}
                                placeholder="Buscar por nº de factura interna o nº de factura del proveedor"
                                onChange={handleChange}
                            />
                        </div>
                    </form>
                    <TableInvoices invoices={invoices} token={token} />
                </div>
            </main>
        </>
    );
}

export default InvoicesPage;
