import React, { useState, useEffect } from 'react'
import Navbar from "../../components/Navbar"
import useAuth from '../../hooks/useAuth';
import { Navigate } from 'react-router-dom';
import Breadcrumbs from "../../components/Breadcrumbs";
import { getAccessToken } from '../../api/auth';
import { setNewMonthInvoice, getMonthInvoice, getMonthInvoiceOut, setNewMonthInvoiceOut } from '../../api/configAdmin';

const ConfigPage = () => {

	const { user, isLoading } = useAuth();

	const [ ref, setRef ] = useState('');

	const [ refOut, setRefOut ] = useState('');

	const [ data, setData ] = useState({
		month: ''
	});

	const [ dataOut, setDataOut ] = useState({
		month: ''
	});


	const token = getAccessToken();

	const [ error, setError ] = useState(false);
	const [ exito, setExito] = useState(false);

	const [ errorOut, setErrorOut ] = useState(false);
	const [ exitoOut, setExitoOut] = useState(false);

	const { month } = data;

	useEffect(() => {

		async function fetchDatos() {
			await getMonthInvoice(token).then(response => {
				setRef(response.ref);
			});
			await getMonthInvoiceOut(token).then(response => {
				setRefOut(response.ref);
			})
		}

		fetchDatos();

	}, [token]);

	const handleChange = e => {
		setData({
			...data,
			[e.target.name] : e.target.value
		})
	};

	const handleChangeOut = e => {
		setDataOut({
			...dataOut,
			[e.target.name] : e.target.value
		})
	};

	const handleSubmit = async e => {

		e.preventDefault();

		if (!month || month.length === 0) {
			return setError({
				msg: 'Debes elegir un mes',
				status: true
			});
		}

		const result = await setNewMonthInvoice(data, token);

		if(!result.ok) {
			setError({
				msg: result.message,
				status: true
			});
			setExito(false);
		} else {
			setError({
				msg: "",
				status: false
			});
			setData({
				month: ''
			});
			setExito(true);
		}

	}

	const handleSubmitOut = async e => {

		e.preventDefault();

		if (!dataOut.month || dataOut.month.length === 0) {
			return setErrorOut({
				msg: 'Debes elegir un mes',
				status: true
			});
		}

		const result = await setNewMonthInvoiceOut(dataOut, token);

		if(!result.ok) {
			setErrorOut({
				msg: result.message,
				status: true
			});
			setExitoOut(false);
		} else {
			setErrorOut({
				msg: "",
				status: false
			});
			setDataOut({
				month: ''
			});
			setExitoOut(true);
		}

	}

	if (!user && isLoading) return 'Cargando...';

	if(!user && !isLoading) {
		return (
			<Navigate to="/login" />
		)
	}

	if (user && user.role !== 'admin') {
		return (
			<Navigate to="/" />
		)
	}

	return ( 
		<>
			<Navbar />
			<main>
				<div className="container pb-5">
					<Breadcrumbs currentPage="Configurar" />
					<div className="row mb-4 pb-3">
						<div className="p-0 col-12 title">
							<h1 className="p-1">Configurar</h1>
						</div>
						<div className="row">
							<div className="card">
								<div className="card-header bg-white mt-2">
									<h4>Mes de facturación</h4>
								</div>
								<div className="card-body">
								<form className="row g-2" onSubmit={handleSubmit}>
									<p className="text-muted">
										Selecciona el mes de facturación permitido. La referencia actual es R-PL14-{ref}XX
									</p>
									<div className="col-auto">
										<p className="text-muted mb-0">Mes</p>
										<select className="form-select" name="month" value={month} onChange={handleChange}>
											<option value="">Mes</option>
											<option value="E">Enero</option>
											<option value="F">Febrero</option>
											<option value="M">Marzo</option>
											<option value="A">Abril</option>
											<option value="MY">Mayo</option>
											<option value="J">Junio</option>
											<option value="JL">Julio</option>
											<option value="AG">Agosto</option>
											<option value="S">Septiembre</option>
											<option value="O">Octubre</option>
											<option value="N">Noviembre</option>
											<option value="D">Diciembre</option>
										</select>
									</div>		
									<div className="col-auto d-flex align-items-center">
										<button type="submit" className="btn btn-primary mt-3">Cambiar</button>
									</div>
									{ error.status ? <div className="alert alert-danger text-center" role="alert">{error.msg}</div> : null }
									{exito ? <div className="mt-3 alert alert-success text-center registro-exitoso" role="alert"><p className="mb-0">Mes cambiado correctamente.</p></div> : null }
								</form>
								</div>
							</div>
						</div>

						<div className="row mt-4">
							<div className="card">
								<div className="card-header bg-white mt-2">
									<h4>Mes de facturación facturas de fuera de la plataforma</h4>
								</div>
								<div className="card-body">
								<form className="row g-2" onSubmit={handleSubmitOut}>
									<p className="text-muted">
										Selecciona el mes de facturación permitido. La referencia actual es R-A14-{refOut}XX
									</p>
									<div className="col-auto">
										<p className="text-muted mb-0">Mes</p>
										<select className="form-select" name="month" value={dataOut.month} onChange={handleChangeOut}>
											<option value="">Mes</option>
											<option value="E">Enero</option>
											<option value="F">Febrero</option>
											<option value="M">Marzo</option>
											<option value="A">Abril</option>
											<option value="MY">Mayo</option>
											<option value="J">Junio</option>
											<option value="JL">Julio</option>
											<option value="AG">Agosto</option>
											<option value="S">Septiembre</option>
											<option value="O">Octubre</option>
											<option value="N">Noviembre</option>
											<option value="D">Diciembre</option>
										</select>
									</div>		
									<div className="col-auto d-flex align-items-center">
										<button type="submit" className="btn btn-primary mt-3">Cambiar</button>
									</div>
									{ errorOut.status ? <div className="alert alert-danger text-center" role="alert">{errorOut.msg}</div> : null }
									{exitoOut ? <div className="mt-3 alert alert-success text-center registro-exitoso" role="alert"><p className="mb-0">Mes cambiado correctamente.</p></div> : null }
								</form>
								</div>
							</div>
						</div>

					</div>
				</div>
			</main>
		</>
	 );
}
 
export default ConfigPage;