import React, { useState, useEffect } from 'react'
import useAuth from '../../hooks/useAuth';
import { getAccessToken } from '../../api/auth';
import { Navigate } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import Breadcrumbs from '../../components/Breadcrumbs';
import { getAllOffers } from '../../api/offer';
import OffersTable from '../../components/Admin/OffersTable';


const OffersAdminPage = () => {

	const { user, isLoading } = useAuth();

	const token = getAccessToken();

	const [offers, setOffers ] = useState([]);

	useEffect(() => {

		async function fetchDatos() {
			await getAllOffers(token).then(response => {
				setOffers(response.offers);
			});
		}

		fetchDatos();

	}, [token]);

	if (!user && isLoading) return 'Cargando...';

	if(!user && !isLoading) {
		return (
			<Navigate to="/login" />
		)
	}

	if (user && user.role !== 'admin') {
		return (
			<Navigate to="/" />
		)
	}

	return (
		<>
			<Navbar />
			<main>
				<div className="container pb-5">
					<Breadcrumbs currentPage="Ofertas" />
					<div className="row mb-4 pb-3">
						<div className="p-0 col-12 title d-flex justify-content-between align-items-center">
							<h1 className="p-1">Ofertas enviadas</h1>
						</div>
					</div>
					<OffersTable offers={offers} token={token} />
				</div>
			</main>
		</>
	)
}

export default OffersAdminPage
