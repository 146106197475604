import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import moment from 'moment';

const ViewInvoiceModal = ({ show, handleClose, invoice, handleEdit, supplier, handleRevised, verificada, error }) => {
	
	const { refSupplier, date, expirationDate, accountName, iban, paypalEmail, prensalinkEmail, description, price, iva, irpf, total, payment, ref, paymentMode, revised } = invoice;


    return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>{ref}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<ul>
					<li><span className="bold">Referencia:</span> {ref}</li>
					<li><span className="bold">Proveedor</span>: {supplier.companyName ? supplier.companyName : supplier.name + ' ' + supplier.surname}</li>
					<li><span className="bold">Fecha:</span> {moment(date).format("DD/MM/YYYY")}</li>
					<li><span className="bold">Vencimiento:</span> {moment(expirationDate).format("DD/MM/YYYY")}</li>
					<li><span className="bold">Número de factura del proveedor:</span> {refSupplier}</li>
					<li><span className="bold">Estado del pago:</span> {payment}</li>
					<li><span className="bold">Método de pago:</span> {paymentMode}</li>
					{paymentMode === 'banco' ? (
						<>
							<li><span className="bold">Beneficiario:</span> {accountName}</li>
							<li><span className="bold">IBAN:</span> {iban}</li>
						</>
					) : null}
					{paymentMode === 'paypal' ? (
						<li><span className="bold">Email de Paypal:</span> {paypalEmail}</li>
					) : null}
					{paymentMode === 'saldo' ? (
						<li><span className="bold">Email de Prensalink:</span> {prensalinkEmail}</li>
					) : null}
					<li><span className="bold">Concepto:</span> {description}</li>
					<li><span className="bold">Precio:</span> {price}</li>
					<li><span className="bold">IVA:</span> {iva}</li>
					<li><span className="bold">IRPF:</span> {irpf}</li>
					<li><span className="bold">Total:</span> {total}</li>
				</ul> 
			</Modal.Body>
			<Modal.Footer>

				{revised ? null : (
					!verificada ? (<button className="btn btn-secondary mx-2" onClick={handleRevised}>Marcar como revisada</button>) : 'Factura verificada correctamente' 
				)
				}
				<Button variant="primary" onClick={handleEdit}>
					Editar
				</Button>
				{ error.status ? <div className="alert alert-danger text-center mt-2" role="alert">{error.msg}</div> : null }
			</Modal.Footer>
		</Modal>
    );
};

export default ViewInvoiceModal;