import React, { useState, useEffect } from 'react';
import LogoForm from '../../assets/img/logo-form.png';
import '../../css/login.css';
import { Navigate, Link } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { getAccessToken } from '../../api/auth';
import { getAllEnterprises } from '../../api/enterprise';
import FormsInvoiceAdmin from '../../components/FormsInvoice/FormsInvoiceAdmin';
import { newInvoice } from '../../api/invoice';

const NewInvoicePageAdmin = () => {

	const { user, isLoading } = useAuth();

	const token = getAccessToken();

	const [ invoice, setInvoice ] = useState({
		date: '',
		expirationDate: '',
		refSupplier: '',
		description: '',
		price: '',
		iva: '',
		irpf: '',
		file: '',
		enterpriseID: ''
	});

	const [ enterprises, setEnterprises ] = useState([]);

	const [ file, setFile ] = useState('');

	const [ total, setTotal ] = useState(0);


	const [ error, setError ] = useState({
		msg: '',
		status: false
	});

	const [ exito, setExito ] = useState(false);

	const { date, expirationDate, refSupplier, description, price, iva, irpf } = invoice;

	const handleChange = e => {
		setInvoice({
			...invoice,
			[e.target.name] : e.target.value
		})
		
	};

	const handleFile = e => {
		setFile(...file, e.target.files[0]);
	}



	useEffect(() => {

		setTotal((Number(price) + Number(iva) - Number(irpf)).toFixed(2));

	}, [price, iva, irpf]);

	useEffect(() => {

		async function fetchDatos() {
			await getAllEnterprises(token).then(response => {
				setEnterprises(response.enterprises);
			});
		}

		fetchDatos();

	}, [token]);


	const handleSubmit = async e => {
		
		e.preventDefault();

		if(date.trim() === '' || expirationDate.trim() === '' || refSupplier.trim() === '' || description.trim() === '' || price.trim() === '') {
			setError({
				msg: 'Alguno de los campos obligatorios no están rellenados.',
				status: true
			});
			return;
		}

		if(!file) {
			setError({
				msg: 'Debes adjuntar la factura para poder darla de alta.',
				status: true
			});
			return;
		} 

		if (date > expirationDate) {
			setError({
				msg: 'La fecha de vencimiento no puede ser previa a la fecha de emisión.',
				status: true
			});
			return;
		}
		
		invoice.file = file;
		
		const result = await newInvoice(invoice, token);

	if(!result.ok) {
			setError({
				msg: result.message,
				status: true
			});
			setExito(false);
			
		} else {
			setError({
				msg: "",
				status: false
			});
			setInvoice({
				date: '',
				expirationDate: '',
				refSupplier: '',
				description: '',
				price: '',
				iva: '',
				irpf: '',
				file: '',
				enterpriseID: ''
			});
			setExito(true);
		}
	}

	if (!user && isLoading) return 'Cargando...';

	if(!user && !isLoading) {
		return (
			<Navigate to="/login" />
		)
	}

	if (user && user.role !== 'admin') {
		return (
			<Navigate to="/" />
		)
	}


	return ( 
		<div className="d-flex justify-content-center align-items-center fullpage">
			<div className="d-flex flex-column align-items-center">
				<img src={LogoForm} alt="Logo" className="logo-login mb-4 px-3" />
				<div className="card fixed-width">
					<div className="card-body">
						{ error.status ? <div className="alert alert-danger text-center" role="alert">{error.msg}</div> : null }
						<FormsInvoiceAdmin 
							handleSubmit={handleSubmit}
							enterprises={enterprises}
							invoice={invoice}
							handleChange={handleChange}
							handleFile={handleFile}
							total={total}
							exito={exito}
							setInvoice={setInvoice}
						/>
					</div>
				</div>
				<div className="mt-3 pb-4">
					<Link className="col-7" to="/admin/invoices">
						<span>Volver al listado de facturas</span>
					</Link>
				</div>
			</div>
			
		</div>
	 );
}
 
export default NewInvoicePageAdmin;
