import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { EyeFill, PatchCheckFill } from 'react-bootstrap-icons';
import { updateEnterprise, verifiedEnterprise, removeOffer, createOfferAlert } from '../../api/enterprise';
import IsMultiple from './IsMultiple';
import ButtonSubmit from '../../components/ButtonSubmit';
import { Link } from 'react-router-dom';

const RowSuppliers = ({supplier, token}) => {

	const { _id, companyName, name, surname, dni, country, email, phone, payment, address, onOffer, cp, city, irpf, iva, accountType, accountName, iban, paypalEmail, prensalinkEmail, accountNumber, verified } = supplier;

	const [ estadoEnterprise, setEstadoEnterprise ] = useState({
		_id: supplier._id || '',
		name: supplier.name || '',
		surname: supplier.surname || '',
		companyName: supplier.companyName || '',
		dni: supplier.dni || '',
		country: supplier.country || '',
		phone: supplier.phone || '',
		payment: supplier.payment || '',
		address: supplier.address || '',
		cp: supplier.cp || '',
		city: supplier.city || '',
		irpf : supplier.irpf || 0,
		iva : supplier.iva || 0,
		accountType: supplier.accountType || '',
		prensalinkEmail: supplier.prensalinkEmail || '',
		iban: supplier.iban || '',
		paypalEmail: supplier.paypalEmail || '',
		accountName: supplier.accountName || '',
		accountNumber: supplier.accountNumber || ''
	})

	const [ exito, setExito ] = useState(false);
	const [ error, setError ] = useState({
		msg: '',
		status: false
	});

	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const [showEdit, setShowEdit] = useState(false);

	const handleCloseEdit = () => setShowEdit(false);

	const handleEdit = () => {
		setShowEdit(true);
		setShow(false)
	}

	const handleChange = e => {
		setEstadoEnterprise({
			...estadoEnterprise,
			[e.target.name] : e.target.value
		})
	}

	const handleSubmit = async e => {
		
		e.preventDefault();

		if(estadoEnterprise.name.trim() === '' || estadoEnterprise.surname.trim() === '' || estadoEnterprise.dni.trim() === '' || estadoEnterprise.address.trim() === '' || estadoEnterprise.cp.trim() === '' || estadoEnterprise.city.trim() === '' || estadoEnterprise.country.trim() === '' || estadoEnterprise.phone.trim() === '' || estadoEnterprise.payment === '') {
			setError({
				msg: 'Alguno de los campos obligatorios no están rellenados.',
				status: true
			});
			setExito(false);
			return;
		}

		const result = await updateEnterprise(estadoEnterprise, token);

		if (result.ok) {
			setExito(true);
			setError({
				msg: '',
				status: false
			})
		} else {
			setError({		
				msg: 'Hubo un error, contacta con soporte.',
				status: true
			});
			setExito(false);
		}
	}

	const removeOfferBtn = async() => {

		const result = await removeOffer(_id, token);

		if (result.ok) {
			setTimeout(function() { 
				window.location.reload();
			}, 400);
		} else {
			setError({		
				msg: 'Hubo un error.',
				status: true
			});
		}
	}

	const createOfferAlertBtn = async() => {

		const result = await createOfferAlert(_id, token);

		if (result.ok) {
			setTimeout(function() { 
				window.location.reload();
			}, 400);
		} else {
			setError({		
				msg: 'Hubo un error.',
				status: true
			});
		}
	}

	const handleVerified = async () => {

		const result = await verifiedEnterprise(_id, token);

		if (result.ok) {
			setTimeout(function() { 
				setShowEdit(false);
				setShow(false);
			}, 400);
		} else {
			setError({		
				msg: 'Hubo un error verificando la cuenta.',
				status: true
			});
		}

	}


	return (
	
		<tr className="bg-white my-2">
			<td className="text-nowrap">
				<div className="d-flex align-items-center">
					{verified ? <PatchCheckFill className="patch-check" /> : null }
					<Link to={`/admin/invoices/${supplier._id}`} className="table-link">
						{companyName === '' ? name + ' ' + surname : companyName}
					</Link>
				</div>
			</td>
			<td className="text-nowrap">{dni}</td>
			<td className="text-nowrap">{country}</td>
			<td className="text-nowrap">{email}</td>
			<td className="text-right text-nowrap">
				<div className="d-flex align-items-center">
					<IsMultiple supplier={supplier} token={token} setError={setError} />
					<Button variant="primary" className="px-2 py-0" onClick={handleShow}>
						<EyeFill />
					</Button>
				</div>
				<Modal show={show} onHide={handleClose}>
					<Modal.Header closeButton>
						<Modal.Title className="d-flex align-items-center">{verified ? <PatchCheckFill className="patch-check" /> : null } {companyName === '' ? name + ' ' + surname : companyName}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<ul>
							<li>Nombre: {name}</li>
							<li>Apellidos: {surname}</li>
							<li>Empresa: {companyName}</li>
							<li>DNI/CIF: {dni}</li>
							{payment === 'banco' ? (
								<>
									<li>Método de pago: Transferencia</li>
									<li>Beneficiario: {accountName}</li>
									<li>IBAN: {iban}</li>
								</>
							) : null}
							{payment === 'paypal' ? (
								<>
									<li>Método de pago: Paypal</li>
									<li>Email de Paypal: {paypalEmail}</li> 
								</>
							) : null}
							{payment === 'saldo' ? (
								<>
									<li>Método de pago: Saldo de Prensalink</li>
									<li>Email de Prensalink: {prensalinkEmail}</li> 
								</>
							) : null} 
							{payment === 'amex' ? (
								<li>Método de pago: Tarjeta de crédito AMEX</li>
							) : null}
							{payment === 'mastercard' ? (
								<li>Método de pago: Tarjeta de crédito Mastercard</li>
							) : null}
							{payment === 'giro' ? (
								<li>Método de pago: Giro bancario</li>
							) : null}
							<li>Tipo de IRPF: {irpf} %</li>
							<li>Tipo de IVA: {iva} %</li>
							<li>Tipo de cuenta: {accountType}</li>
							<li>Número de cuenta: {accountNumber}</li>
							<li>Dirección: {address}</li>
							<li>Código Postal: {cp}</li>
							<li>Localidad: {city}</li>
							<li>País: {country}</li>
							<li>En oferta: {onOffer ? 'Si' : 'No'} {!onOffer ? <button className="btn btn-primary btn-sm" onClick={createOfferAlertBtn}>Activar oferta</button> : <button className="btn btn-danger btn-sm" onClick={removeOfferBtn}>Quitar oferta</button>}</li>
						</ul>
					</Modal.Body>
					<Modal.Footer> 
						<div>
							{verified ? null : <button className="btn btn-secondary mx-2" onClick={handleVerified}>Verificar</button> }
							<Button variant="primary" onClick={handleEdit}>
								Editar
							</Button>
						</div>
						{ error.status ? <div className="alert alert-danger text-center mt-2" role="alert">{error.msg}</div> : null }
					</Modal.Footer>
				</Modal>
				<Modal show={showEdit} onHide={handleCloseEdit}>
					<Modal.Header closeButton>
						<Modal.Title>Editar {companyName === '' ? name + ' ' + surname : companyName}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<form onSubmit={handleSubmit}>
							{ error.status ? <div className="alert alert-danger text-center" role="alert">{error.msg}</div> : null }
							{exito ? <div className="mt-3 alert alert-success text-center registro-exitoso" role="alert">Perfil editado correctamente</div> : null }
							<div className="mb-3">
								<input 
									type="text" 
									className="form-control" 
									name="name" 
									onChange={handleChange}
									value={estadoEnterprise.name}
									placeholder="Nombre" 
								/>
							</div>
							<div className="mb-3">
								<input 
									type="text" 
									className="form-control" 
									name="surname" 
									onChange={handleChange}
									value={estadoEnterprise.surname}
									placeholder="Apellidos" 
								/>
							</div>
							<div className="mb-3">
								<input 
									type="text" 
									className="form-control" 
									name="companyName" 
									onChange={handleChange}
									value={estadoEnterprise.companyName}
									placeholder="Nombre de la empresa (opcional)" 
								/>
							</div>
							<div className="mb-3">
								<input 
									type="text" 
									className="form-control" 
									name="dni" 
									onChange={handleChange}
									value={estadoEnterprise.dni}
									placeholder="DNI / CIF(empresas)" 
								/>
							</div>
							<div className="mb-3">
								<select className="form-select" name="payment" value={estadoEnterprise.payment} onChange={handleChange}>
									<option value="">Forma de pago</option>
									<option value="banco">Transferencia bancaria</option>
									<option value="paypal">Paypal</option>
									<option value="saldo">Saldo de Prensalink</option>
								</select>
							</div>
							{ estadoEnterprise.payment === 'banco' ? (
							<>
								<div className="mb-3">
									<input 
										type="text" 
										className="form-control" 
										name="accountName" 
										onChange={handleChange}
										value={estadoEnterprise.accountName}
										placeholder="Nombre del beneficiario de la transferencia" 
									/>
								</div> 
								<div className="mb-3">
									<input 
										type="text" 
										className="form-control" 
										name="iban" 
										onChange={handleChange}
										value={estadoEnterprise.iban}
										placeholder="Número de cuenta (IBAN)" 
									/>
								</div> 
							</>
							): null}
							{ estadoEnterprise.payment === 'paypal' ? (
								<div className="mb-3">
									<input 
										type="text" 
										className="form-control" 
										name="paypalEmail" 
										onChange={handleChange}
										value={estadoEnterprise.paypalEmail}
										placeholder="Email de Paypal" 
									/>
								</div> 
							): null}
							{ estadoEnterprise.payment === 'saldo' ? (
								<div className="mb-3">
									<input 
										type="text" 
										className="form-control" 
										name="prensalinkEmail" 
										onChange={handleChange}
										value={estadoEnterprise.prensalinkEmail}
										placeholder="Email de Prensalink" 
									/>
								</div> 
							): null}
							<div className="mb-3">
								<input 
									type="number" 
									className="form-control" 
									name="irpf" 
									onChange={handleChange}
									value={estadoEnterprise.irpf}
									placeholder="Tipo de IRPF" 
								/>
							</div>
							<div className="mb-3">
								<input 
									type="number" 
									className="form-control" 
									name="iva" 
									onChange={handleChange}
									value={estadoEnterprise.iva}
									placeholder="Tipo de IVA" 
								/>
							</div>
							<div className="mb-3">
								<input 
									type="text" 
									className="form-control" 
									name="accountType" 
									onChange={handleChange}
									value={estadoEnterprise.accountType}
									placeholder="Tipo de cuenta" 
								/>
							</div>
							<div className="mb-3">
								<input 
									type="text" 
									className="form-control" 
									name="accountNumber" 
									onChange={handleChange}
									value={estadoEnterprise.accountNumber}
									placeholder="Número de cuenta" 
								/>
							</div>
							<div className="mb-3">
								<input 
									type="text" 
									className="form-control" 
									name="address" 
									onChange={handleChange}
									value={estadoEnterprise.address}
									placeholder="Dirección de facturación" 
								/>
							</div>
							<div className="mb-3">
								<input 
									type="text" 
									className="form-control" 
									name="cp" 
									onChange={handleChange}
									value={estadoEnterprise.cp}
									placeholder="Código Postal" 
								/>
							</div>
							<div className="mb-3">
								<input 
									type="text" 
									className="form-control" 
									name="city" 
									onChange={handleChange}
									value={estadoEnterprise.city}
									placeholder="Localidad" 
								/>
							</div>
							<div className="mb-3">
								<input 
									type="text" 
									className="form-control" 
									name="country" 
									onChange={handleChange}
									value={estadoEnterprise.country}
									placeholder="País" 
								/>
							</div>
							<div className="mb-3">
								<input 
									type="text" 
									className="form-control" 
									name="phone" 
									onChange={handleChange}
									value={estadoEnterprise.phone}
									placeholder="Teléfono" 
								/>
							</div>
							<ButtonSubmit text="Enviar" />
						</form>
					</Modal.Body>
					<Modal.Footer>
					</Modal.Footer>
				</Modal>
			</td>
		</tr> 
	);
}
 
export default RowSuppliers;