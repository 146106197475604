import React, { useState, useEffect } from 'react'
import { Button, Modal } from "react-bootstrap";
import { DatabaseFillAdd } from 'react-bootstrap-icons';
import { isMultipleFn } from '../../api/enterprise';
import { getAllUsers, assignEnterprise } from '../../api/user';
import ButtonSubmit from '../ButtonSubmit';


const IsMultiple = ({supplier, token}) => {

	const { _id, isMultiple, companyName, name, surname } = supplier;

	const [show, setShow] = useState(false);

	const [data, setData] = useState({
		email: '',
		idEnterprise: ''
	});

	const { email } = data;

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const [ users, setUsers ] = useState([]);

	const [ error, setError ] = useState({
		msg: '',
		status: false
	});

	const [ exito, setExito ] = useState(false);

	useEffect(() => {

		async function fetchDatos() {
			await getAllUsers(token).then(response => {
				setUsers(response.users);
			});
		}

		fetchDatos();

	}, [token]);

	const handleChange = e => {
		setData({
			...data,
			[e.target.name] : e.target.value
		})
		
	};

	const handleSubmit = async e => {

		e.preventDefault();

		if(!email) {
			setError({
				msg: 'Debes seleccionar un email.',
				status: true
			});
			return;
		}

		data.idEnterprise = _id;

		const result = await assignEnterprise(data,token);

		if(!result.ok) {
			setError({
				msg: result.message,
				status: true
			});
			setExito(false);
			
		} else {
			setError({
				msg: "",
				status: false
			});
			setData({
				email: '',
				idEnterprise: ''
			});
			setExito(true);
		}
	}


	const handleMultiple = async() => {

		const result = await isMultipleFn(_id, token);

		if (result.ok) {

			setTimeout(function() { 
				window.location.reload();
			}, 400);

		} else {
			setError({		
				msg: result.message,
				status: true
			});
		}

	}

	return ( 
		<>
			{isMultiple ? (
				<Button variant="primary" className="px-2 py-0 mx-1" onClick={handleShow}>
					<DatabaseFillAdd />
				</Button>
			) : (
			<Button variant="warning" className="px-2 py-0 mx-1" onClick={handleShow}>
				<DatabaseFillAdd />
			</Button>)}
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<h4>Múltiples usuarios para {companyName === '' ? name + ' ' + surname : companyName}</h4>
				</Modal.Header>
				<Modal.Body className="py-4 px-4">
					{isMultiple ? (
						<div>
							<p>Asignar un usuario a este proveedor:</p>
							{ error.status ? <div className="alert alert-danger text-center" role="alert">{error.msg}</div> : null }
							<form onSubmit={handleSubmit}>
								<div className="mb-3">
										<select className="form-select" name="email" value={email} onChange={handleChange}>
											{users.map(item => {
												return (<option key={item._id} value={item.email}>{item.email}</option>);
											})}
										</select>
								</div>
								<ButtonSubmit text="Asignar usuario" />
							</form>
							{exito ? <div className="mt-3 alert alert-success text-center registro-exitoso" role="alert">Usuario asignado correctamente.</div> : null }
						</div>
					) : (
						<>
							<p className="mb-4">¿Estás seguro de que quieres permitir múltiples usuarios para este proveedor?</p>
							<Button variant="primary" onClick={handleMultiple}>
								Confirmar
							</Button>
							<Button variant="secondary" className="mx-2" onClick={handleClose}>
								Volver
							</Button>
						</>
					)}
					
				</Modal.Body>
			</Modal>
		</>

	 );
}
 
export default IsMultiple;