import { useState, useEffect } from 'react';
import Navbar from "../components/Navbar"
import Breadcrumbs from "../components/Breadcrumbs"
import useAuth from '../hooks/useAuth';
import { Navigate } from "react-router-dom";
import NewProfilePage from "./NewProfilePage";
import { getUserEnterprise, updateEnterprisePayment } from '../api/enterprise';
import { getAccessToken } from '../api/auth';
import { logout } from '../api/auth';
import { useTranslation } from 'react-i18next';

const Profile = () => {

	const {t} = useTranslation();

	const { user, isLoading } = useAuth();

	const [ enterprise, setEnterprise ] = useState([]);

	const { companyName, dni, name, surname, address, cp, city, country, phone, payment, accountName, iban, paypalEmail, prensalinkEmail  } = enterprise;


	const [ estadoPago, setEstadoPago ] = useState({
		_id: '',
		payment: '',
		prensalinkEmail:  '',
		iban: '',
		paypalEmail: '',
		accountName:  ''
	});

	const [ exito, setExito ] = useState(false);
	const [ error, setError ] = useState({
		msg: '',
		status: false
	});

	
	const [show, setShow] = useState(false);

	const handleShow = () => setShow(true);

	const token = getAccessToken();

	useEffect(() => {

		async function fetchDatos() {
			await getUserEnterprise(token).then(response => {
				setEnterprise(response.enterprise);
				setEstadoPago({
					_id: response.enterprise._id,
					payment: response.enterprise.payment,
					prensalinkEmail: response.enterprise.prensalinkEmail,
					iban: response.enterprise.iban,
					paypalEmail: response.enterprise.paypalEmail,
					accountName: response.enterprise.accountName
				})
			});
		}

		fetchDatos();

	}, [token]);

	const handleClick = () => {
		logout();
		window.location.href = "/";
	}

	const handleChange = e => {
		setEstadoPago({
			...estadoPago,
			[e.target.name] : e.target.value
		})
	}

	const handleSubmit = async e => {
		
		e.preventDefault();

		if(estadoPago.payment === '') {
			setError({
				msg: t('Es obligatorio rellenar el modo de pago.'),
				status: true
			});
			setExito(false);
			return;
		}

		const result = await updateEnterprisePayment(estadoPago, token);

		if (result.ok) {
			setExito(true);
			setError({
				msg: '',
				status: false
			});
			setTimeout(function() { 
				window.location.reload();
			}, 400);
		} else {
			setError({		
				msg: t('Hubo un error, comprueba que todos los campos estén rellenados correctamente.'),
				status: true
			});
			setExito(false);
		}
	}

	if (!user && isLoading) return 'Cargando...';

	if(!user && !isLoading) {
		return (
			<Navigate to="/login" />
		)
	}

	if(!enterprise) {
		return 'Cargando...';
	}

	if (user.invoiceProfile) {
	return (
		<div>
			<Navbar />
			<main>
			<div className="container">
				<Breadcrumbs currentPage={t('Perfil')} />
				<div className="row mb-4 pb-3">
					<div className="p-0 col-12 title">
						<h1 className="p-1">{t('Mi Perfil')}</h1>
					</div>
				</div>
				<div className="row">
					<div className="card">
						<div className="card-header bg-white mt-2">
							<h4>{t('Datos de la cuenta')}</h4>
						</div>
						<div className="card-body">
							<b>Email</b>
							<p>{user.email}</p>
							{companyName ? (
								<>
									<b>{t('Empresa')}</b>
									<p>{companyName}</p>
									<b>{t('CIF')}</b>
									<p>{dni}</p>
								</>
							) : (
								<>
									<b>{t('Nombre')}</b>
									<p>{name} {surname}</p>
									<b>{t('DNI')}</b>
									<p>{dni}</p>
								</>
							)}
							<b>{t('Dirección')}</b>
							<p>{address}, {cp}, {city}, {country}</p>
							<b>{t('Teléfono')}</b>
							<p>{phone}</p>
							<b>{t('Forma de pago')}</b>
							{ payment === 'banco' ? (
								<>
									<p>{t('Transferencia')}</p>
									<b>{t('Beneficiario')}</b>
									<p>{accountName}</p>
									<b>IBAN</b>
									<p>{iban}</p>
								</>
							) : null }
							{ payment === 'paypal' ? (
								<>
									<p>Paypal</p>
									<b>{t('Email de paypal')}:</b>
									<p>{paypalEmail}</p>
								</>
							) : null }
							{ payment === 'saldo' ? (
								<>
									<p>{t('Saldo de Prensalink')}</p>
									<b>{t('Email de Prensalink')}:</b>
									<p>{prensalinkEmail}</p>
								</>
							 ) : null }
							
							{show ? (
								<form onSubmit={handleSubmit}>
									<div className="mb-3">
										<select className="form-select" name="payment" value={estadoPago.payment} onChange={handleChange}>
											<option value="">{t('Forma de pago')}</option>
											<option value="banco">{t('Transferencia bancaria')}</option>
											<option value="paypal">Paypal</option>
											<option value="saldo">{t('Saldo de Prensalink')}</option>
										</select>
									</div>
									{ estadoPago.payment === 'banco' ? (
									<>
										<div className="mb-3">
											<input 
												type="text" 
												className="form-control" 
												name="accountName" 
												onChange={handleChange}
												value={estadoPago.accountName}
												placeholder={t('Nombre del beneficiario de la transferencia')}
											/>
										</div> 
										<div className="mb-3">
											<input 
												type="text" 
												className="form-control" 
												name="iban" 
												onChange={handleChange}
												value={estadoPago.iban}
												placeholder={t('Número de cuenta (IBAN)')} 
											/>
										</div> 
									</>
									): null}
									{ estadoPago.payment === 'paypal' ? (
										<div className="mb-3">
											<input 
												type="text" 
												className="form-control" 
												name="paypalEmail" 
												onChange={handleChange}
												value={estadoPago.paypalEmail}
												placeholder={t('Email de Paypal')} 
											/>
										</div> 
									): null}
									{ estadoPago.payment === 'saldo' ? (
										<div className="mb-3">
											<input 
												type="text" 
												className="form-control" 
												name="prensalinkEmail" 
												onChange={handleChange}
												value={estadoPago.prensalinkEmail}
												placeholder={t('Email de Prensalink')} 
											/>
										</div> 
									): null}
									<button className="btn btn-primary">{t('Guardar cambios')}</button>
								</form>
							) : null }
							{ !show ? (
								<div className="mt-4 mb-3">
									{enterprise.isMultiple ? <p>Este proveedor tiene varios usuarios. Por favor contacta con soporte para editar la forma de pago.</p> : (
										<button className="btn btn-primary" onClick={handleShow}>{t('Editar forma de pago')}</button>
									)}
								</div>
							) : null }

								{ error.status ? <div className="alert alert-danger text-center mt-3" role="alert">{error.msg}</div> : null }
								{exito ? <div className="mt-3 alert alert-success text-center registro-exitoso" role="alert">{t('Forma de pago editada correctamente')}</div> : null }

						</div>
					</div>
					<div className="mt-4 mb-3 d-flex justify-content-end">
						<button className="btn btn-secondary" onClick={handleClick}>{t('Cerrar sesión')}</button>
					</div>
				</div>
			</div>
			</main>
		</div>
	  );
	} else {
		return (
			<NewProfilePage />
		)
	  }
}
 
export default Profile;