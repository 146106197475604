import { basePath } from './config';

export async function signUpApi(data) {
  const url = `${basePath}/api/users/register`;
  const params = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json"
    }
  };

  return await fetch(url, params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      if (result.user) {
        return { ok: true, message: "Usuario creado correctamente" };
      }
      return { ok: false, message: result.msg };
    })
    .catch(err => {
      return { ok: false, message: err.msg };
    });
};

export async function loginApi(data) {
	const url = `${basePath}/api/users/login`;
	const params = {
		method: 'POST',
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json'
		}
	};

	return await fetch(url, params)
		.then(response => {
			return response.json();
		})
		.then(result => {
			return result;
		})
		.catch(err => {
			return err.message;
		})
};

export async function getAllUsers(token) {
	const url = `${basePath}/api/users/`;
	const params = {
	  method: "GET",
	  body: JSON.stringify(),
	  headers: {
		"Content-Type": "application/json",
		"Authorization": token
	  }
	};
  
	return await fetch(url, params)
		.then(response => {
			return response.json();
		})
		.then(result => {
			return result;
		})
		.catch(err => {
			return err.message;
		})
  };

  export async function assignEnterprise(data, token) {
	const url = `${basePath}/api/users/assign-enterprise`;
	const params = {
	  method: "POST",
	  body: JSON.stringify(data),
	  headers: {
		"Content-Type": "application/json",
		"Authorization": token
	  }
	};
  
	return await fetch(url, params)
	  .then(response => {
		return response.json();
	  })
	  .then(result => {
		if (result.user) {
		  return { ok: true, message: "Usuario editado correctamente" };
		}
		return { ok: false, message: result.msg };
	  })
	  .catch(err => {
		return { ok: false, message: err.msg };
	  });
  };

export async function helpApi(data, token) {
	const url = `${basePath}/api/users/help`;
	const params = {
	  method: "POST",
	  body: JSON.stringify(data),
	  headers: {
		"Content-Type": "application/json",
		"Authorization": token
	  }
	};
  
	return await fetch(url, params)
	  .then(response => {
		return response.json();
	  })
	  .then(result => {
		if (result.info) {
		  return { ok: true, message: "Mensaje enviado correctamente" };
		}
		return { ok: false, message: result.msg };
	  })
	  .catch(err => {
		return { ok: false, message: err.msg };
	  });
  };

  export async function offerApi(data, token) {
	const url = `${basePath}/api/users/offer`;
	const params = {
	  method: "POST",
	  body: JSON.stringify(data),
	  headers: {
		"Content-Type": "application/json",
		"Authorization": token
	  }
	};
  
	return await fetch(url, params)
	  .then(response => {
		return response.json();
	  })
	  .then(result => {
		if (result.info) {
		  return { ok: true, message: "Oferta enviada correctamente" };
		}
		return { ok: false, message: result.msg };
	  })
	  .catch(err => {
		return { ok: false, message: err.msg };
	  });
  };

  export function forgotPasswordApi(data) {
	const url = `${basePath}/api/users/forgot-password`;
	const params = {
		method: 'POST',
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json'
		}
	};

	return fetch(url, params)
		.then(response => {
			return response.json();
		})
		.then(result => {
			return result;
		})
		.catch(err => {
			return err.message;
		})
};

export function newPasswordApi(token, newPassword) {

	const data = {
		token,
		newPassword
	};

	const url = `${basePath}/api/users/new-password`;
	const params = {
		method: 'POST',
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json'
		}
	};

	return fetch(url, params)
		.then(response => {
			return response.json();
		})
		.then(result => {
			return result;
		})
		.catch(err => {
			return err.message;
		})
};
