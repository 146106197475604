import useAuth from '../../hooks/useAuth';
import { Navigate } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import Breadcrumbs from '../../components/Breadcrumbs';
import { useState, useEffect } from 'react';
import { getLastNumberReport, downloadPaymentReport, updatePaymentReport } from '../../api/paymentReport';
import { getAccessToken } from '../../api/auth';
import DownloadInvoices from '../../components/Admin/DownloadInvoices';
import ExtendedReport from '../../components/Admin/ExtendedReport';
import DownloadInvoicesOut from '../../components/Admin/DownloadInvoicesOut';
import ExtendedReportOut from '../../components/Admin/ExtendedReportOut';

const ReportsPage = () => {

	const { user, isLoading } = useAuth();

	const [ infoNumber, setInfoNumber ] = useState(0);

	const [ form, setForm ] = useState({
		month: '',
		first: 0,
		last: 0,
		number: 0
	});

	const [ error, setError ] = useState(false);

	const token = getAccessToken();

	const {first, last, number, month } = form;

	useEffect(() => {

		async function fetchDatos() {
			await getLastNumberReport(token).then(response => {
				setInfoNumber(response.newNumber);
			});
		}

		fetchDatos();

	}, [token]);

	const handleChange = e => {
		setForm({
			...form,
			[e.target.name] : e.target.value
		})
	};


	if (!user && isLoading) return 'Cargando...';

	if(!user && !isLoading) {
		return (
			<Navigate to="/login" />
		)
	}

	if (user && user.role !== 'admin') {
		return (
			<Navigate to="/" />
		)
	}

	const handleSubmit = async e => {

		e.preventDefault();

		if (Number(number) !== (Number(infoNumber) + 1)) {
			return setError(true);
		}

		await downloadPaymentReport(form, token);
		await updatePaymentReport(form, token);

	}

	return ( 
		<>
			<Navbar />
			<main>
				<div className="container pb-5">
					<Breadcrumbs currentPage="Descargas" />
					<div className="row mb-4 pb-3">
						<div className="p-0 col-12 title">
							<h1 className="p-1">Descargas</h1>
						</div>
					</div>
					<div className="row">
						<div className="card">
							<div className="card-header bg-white mt-2">
								<h4>Ficheros de pago por transferencia</h4>
							</div>
							<div className="card-body">
							{ error ? <div className="alert alert-danger text-center mt-2" role="alert">El número de fichero no es correcto</div> : null }
								<form className="row g-5" onSubmit={handleSubmit}>
									<p className="text-muted">
										Debes indicar la referencia inicial y final de las facturas que quieres pagar. Por ejemplo, desde la R-PL14-E1 a la R-PL14-E100, deberás indicar 1 y 100. El fichero anterior es {infoNumber}, debes indicar el número {Number(infoNumber) + 1}.
									</p>
									<div className="col-auto">
										<p className="text-muted mb-0">Mes</p>
										<select className="form-select" name="month" value={month} onChange={handleChange}>
											<option value="">Mes</option>
											<option value="E">Enero</option>
											<option value="F">Febrero</option>
											<option value="M">Marzo</option>
											<option value="A">Abril</option>
											<option value="MY">Mayo</option>
											<option value="J">Junio</option>
											<option value="JL">Julio</option>
											<option value="AG">Agosto</option>
											<option value="S">Septiembre</option>
											<option value="O">Octubre</option>
											<option value="N">Noviembre</option>
											<option value="D">Diciembre</option>
										</select>
									</div>		
									<div className="col-auto">
										<p className="text-muted mb-0">Referencia inicial</p>
										<input 
											type="number" 
											className="form-control" 
											placeholder="Ref. inicial"
											name="first"
											value={first}
											onChange={handleChange}
										/>
									</div>
									<div className="col-auto">
										<p className="text-muted mb-0">Referencia final</p>
										<input 
											type="number" 
											className="form-control" 
											placeholder="Ref. final"
											name="last"
											value={last}
											onChange={handleChange}
										/>
									</div>
									<div className="col-auto">
										<p className="text-muted mb-0">Número de fichero</p>
										<input 
											type="number" 
											className="form-control" 
											placeholder="Nº de fichero"
											name="number"
											value={number}
											onChange={handleChange}
										/>
									</div>
									<div className="col-auto d-flex align-items-center mt-4">
										<button type="submit" className="btn btn-primary mt-3">Descargar</button>
									</div>
								</form>
							</div>
						</div>
					</div>
					<DownloadInvoices token={token} />
					<ExtendedReport token={token} />
					<DownloadInvoicesOut token={token} />
					<ExtendedReportOut token={token} />
				</div>
			</main>
		</>
	 );
}
 
export default ReportsPage;